$txt-black: #22292F;
$gray-200: #E5E7EB;
$blue-500: #02577E;

.epa{
  &-footer{
    font-family: $font-primary;
    color: $txt-black;
    background-color: $white;
    height: auto;

    &--transparent{
      background-color: transparent;
    }

    &-wrapper{
      display: flex;
      border-top: 1px solid $gray-200;
      box-sizing: border-box;
      padding: 1.5rem 1.75rem 2rem;
      flex-wrap: wrap;
    }
   &__logo{
     width: 14.12rem;
     @media (max-width: 580px) {
       width: 9.38rem;
     }
   }
    &__col{
      @media (max-width: 580px) {
        display: flex;
        flex-direction: column;
      }
      &--links{
        @media (max-width: 580px) {
          margin-top: 1.25rem;
        }
        .epa-footer__title{
          display: none;
          @media (max-width: 580px) {
            display: block;
          }
        }
        br{
          @media (max-width: 580px) {
            display: none;
          }
        }
        a{
          @media (max-width: 580px) {
            width: auto;
          }
        }
      }
      &--logo{
        margin-right: 4rem;
        @media (max-width: 1140px) {
          width: 100%;
          margin: 0 0 1.25rem;
        }
      }
      &--location{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 580px) {
         order: 1;
          width: 100%;
        }
      }
      &--social{
        align-self: flex-end;
        display: flex;
        margin-left: auto;
        @media (max-width: 580px) {
          margin: 0 0 1.25rem;
          flex-direction: column;
          width: 100%;
        }
      }
    }
    &__location{
      width: 29rem;
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      @media (max-width: 767px) {
        width: auto;
        flex-direction: column;
      }
    }
    &__title{
      font-size: 1rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      @media (max-width: 580px) {
        margin: 0 0 .65rem !important;
        flex-direction: column;
        align-items: flex-start;
        font-size: .88rem;
      }
    }
    &__data{
      font-size: 1rem;
      font-weight: 400;
      white-space: nowrap;
      line-height: 1.4;
      margin: 0;
      @media (max-width: 580px) {
        font-size: .88rem;
      }
    }
    &__link{
      color: $blue-500;
      &:hover{
        text-decoration: underline !important;
      }
    }

    &__social{
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      margin: 0 .25rem;
      @media (max-width: 580px) {
        width: 1.2rem;
        min-width: 1.2rem;
        height: 1.2rem;
        margin: 0 .2rem;
      }
      &-list{
        display: flex;
        margin: 0 -.25rem 0 .75rem;
        @media (max-width: 580px) {
          margin-left: -.2rem;
        }
      }
    }
  }
}
