.handle_mark_did {
  cursor: pointer;
}

.ph-edit-comment {
  .cke_top {
    position: static !important;
  }
}

.ph-p-0 {
  padding: 0 !important;
}

.ph {

  &-text-data {
    display: flex;
    flex-direction: column;
    gap: 22px;
    font-size: 16px;
    line-height: 1.4rem;
    color: $gray-800;
  }

  &-headline {
    margin-bottom: 24px !important;
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  &-container {
    width: 100%;
    display: flex;
    gap: 45px;
    align-items: flex-start;
    margin-bottom: 99px;
    @media (max-width: 1366px) {
      gap: 24px;
      margin-bottom: 48px;
    }
    @media (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
      flex-direction: column-reverse;
      gap: 24px;
    }
  }

  &-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $gray-200;
    flex-grow: 1;
    @media (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
      width: 100%;
    }
  }
  &-text-data, &-data{
    p{
      color: $gray-800;
      font-size: 16px;
      line-height: 1.4;
      margin: 0;
    }
    h3, h4, h5, h6{
      color: $gray-800;
      line-height: 1.4;
    }
    h3{
      font-size: 18px;
    }
    blockquote{
      border: none;
      position: relative;
      box-sizing: border-box;
      padding-left: 18px;
      color: $gray-800;
      margin: 0;
      font-size: 16px;
      line-height: 1.4;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        border-radius: 2px;
        background-color: $blue-1;
      }
      p{
        padding: 0;
        margin:  0;
        font-weight: 400 !important;
        color: $gray-800 !important;
      }
    }
    ol {
      font-size: 16px;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px
    }
    ul {
      font-size: 16px;
      line-height: 1.4;
      list-style-type: none;
      color: $gray-800;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: transparent;

      li {
        position: relative;
        padding-left: 14px;
        color: $gray-800 !important;

        &:before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $blue-300;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }

    }
    a{
      color: $blue-1;
      text-decoration: underline !important;
    }
  }




  &-aside {
    width: 260px;
    min-width: 260px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    background-color: $white;
    border: 1px solid $gray-200;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
      width: 100%;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 100%;
      max-width: 400px;
      min-height: 38px;
      padding: 6px;
      background-color: $blue-1;
      cursor: pointer;
      border: none;
      text-decoration: none;
      font-size: 14px;
      font-family: $font-primary;
      text-transform: uppercase;
      line-height: 24px;
      color: $white;
      border-radius: 4px;
      transition: opacity .2s linear;

      &:hover {
        opacity: .8;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 6px;
      }
    }

    &__label {
      color: $gray-800;
      font-size: 12px;
      line-height: 1.4;
      margin: 0;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__head {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
        color: $gray-800;
      }

      &__body {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
      }
    }

    &-link {
      display: inline-flex;
      width: auto;
      align-items: center;
      gap: 6px;
      color: $blue-1;
      transition: opacity .15s linear;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &__text {
        font-size: 12px;
        font-weight: 400;
      }

      &__icon {
        font-size: 12px;
        height: 12px;
        width: 12px;
        min-width: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-note {
    position: relative;
    box-sizing: border-box;
    padding-left: 18px;
    color: $gray-800;
    margin: 0;
    font-size: 16px;
    line-height: 1.4;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      border-radius: 2px;
      background-color: $blue-1;
    }
  }

  &-text {
    color: $gray-800;
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
  }

  &-ul {
    font-size: 16px;
    line-height: 1.4;
    list-style-type: none;
    color: $gray-800;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-li {
    position: relative;
    padding-left: 14px;

    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $blue-300;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }

  &-subtitle {
    margin: 0;
    color: $gray-900;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4;
  }

  &-subs-item {
    width: 100%;
    display: flex;
    gap: 11px;
    color: $gray-800;

    &.active {
      .ph-subs-item__icon {
        color: $orange;
      }
    }

    &.disabled {
      color: #A1A1A1;

      .ph-subs-item__icon {
        color: #A1A1A1;
      }

      .ph-subs-item__check-label {
        color: #A1A1A1;

        &:before {
          border-color: #A1A1A1;
        }

        &:after {
          color: #A1A1A1;
        }
      }
    }

    &__icon {
      width: 42px;
      min-width: 42px;
      height: 42px;
      box-sizing: border-box;
      line-height: 1;
      border-radius: 50%;
      border: 1px solid $gray-200;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $blue-1;
    }

    &__data {
      padding: 3px 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      line-height: 1.4;
      flex-grow: 1;
    }

    &__name {
      font-size: 17px;
    }

    &__date {
      font-size: 14px;
    }
    &__about{
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      white-space: normal;
    }

    &__check {
      padding: 3px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &-field {
        display: none;

        &:checked ~ .ph-subs-item__check-label:after {
          opacity: 1;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        color: $blue-1;
        font-size: 14px;
        line-height: 1.4;
        cursor: pointer;

        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          border: 1px solid $blue-1;
          border-radius: 2px;
        }

        &:after {
          content: '\e92e';
          font-family: icomoon;
          display: inline-flex;
          width: 11px;
          height: 12px;
          font-size: 14px;
          color: $blue-1;
          line-height: 1;
          position: absolute;
          left: 1px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity .1s linear;
        }

        &.checked:after {
          opacity: 1;
        }
      }
    }
  }

  &-card {
    width: calc(100% / 3 - 11px);
    overflow: hidden;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $gray-200;
    @media (max-width: 1199px) {
      width: calc(50% - 8px);
    }
    @media (max-width: 580px) {
      width: 100%;
    }

    &__img {
      height: 156px;
      width: 100%;
      display: block;
    }

    &__data {
      box-sizing: border-box;
      padding: 18px 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__about {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__title {
      margin: 0;
      color: $blue-1;
      font-size: 15px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__description {
      font-size: 14px;
      line-height: 1.5;
      color: $black;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    &__link {
      display: inline-flex;
      width: auto;
      align-items: center;
      color: $blue-1 !important;
      transition: opacity .15s linear;
      cursor: pointer;
      font-weight: 700;

      &:hover {
        opacity: .8;
      }

      &-text {
        font-size: 14px;
      }

      &-icon {
        font-size: 18px;
        line-height: 1;
        color: #02577e;
        display: inline-block;
        font-style: normal;
        width: 18px;
        height: 18px;
        position: relative;
        &:before{
          content: "›";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__btn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      min-width: 24px;
      font-size: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $blue-1;
      font-style: normal;
      text-decoration: none;

      &-wrapper {
        margin-top: auto;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
      }

      &-list {
        margin-left: auto;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__like {
      &:before {
        content: '\e929';
        font-family: icomoon;
        line-height: 1;
        color: $orange;
      }

      &.like:before {
        content: '\e901';
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 47px;
    }
  }

  &-table {

    &__tr {
      display: flex;
      border-bottom: 1px solid $gray-200;
      box-sizing: border-box;
      padding: 10px 0 10px 16px;
      gap: 10px;
      color: $gray-800;
      @media (max-width: 667px) {
        padding-left: 0;
      }

      &.active {

        .ph-table__icon {
          color: $orange;
        }
      }

      &.disabled {
        color: #A1A1A1;

        .ph-table__icon {
          color: #A1A1A1;
        }

        .ph-table__check-label {
          color: #A1A1A1;

          &:before {
            border-color: #A1A1A1;
          }

          &:after {
            color: #A1A1A1;
          }
        }
      }
    }

    &__td {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      &--status {
        width: 46.75%;
        min-width: 300px;
        @media (max-width: 667px) {
          min-width: 0;
          width: auto;
          flex-grow: 1;
        }
      }

      &--date {
        flex-grow: 1;
        @media (max-width: 667px) {
          display: none;
        }
      }

      &--check {
        width: 90px;
      }
    }

    &__tile {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      color: $blue-1;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 667px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__mobile-date {
      display: none;
      font-size: 10px;
      line-height: 1;
      @media (max-width: 667px) {
        display: inline-block;
      }
    }

    &__data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }

    &__icon {
      width: 42px;
      min-width: 42px;
      height: 42px;
      box-sizing: border-box;
      line-height: 1;
      border-radius: 50%;
      border: 1px solid $gray-200;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $blue-1;
    }

    &__check {
      &-field {
        display: none;

        &:checked ~ .ph-table__check-label:after {
          opacity: 1;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        color: $blue-1;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        white-space: nowrap;
        @media (max-width: 667px) {
          font-size: 12px;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 18px;
          min-width: 18px;
          height: 18px;
          border: 1px solid $blue-1;
          border-radius: 2px;
          @media (max-width: 667px) {
            width: 14px;
            min-width: 14px;
            height: 14px;
          }
        }

        &:after {
          content: '\e92e';
          font-family: icomoon;
          display: inline-flex;
          width: 15px;
          height: 15px;
          font-size: 15px;
          color: $blue-1;
          line-height: 1;
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity .1s linear;
          @media (max-width: 667px) {
            left: 1px;
            width: 12px;
            height: 12px;
            font-size: 12px;
          }
        }

        &.checked:after {
          opacity: 1;
        }
      }
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      box-sizing: border-box;
      padding: 34px 34px 51px 34px;
      border-radius: 4px;
      background-color: $white;
      border: 1px solid $gray-200;
      @media (max-width: 667px) {
        padding: 24px 15px 28px 15px;
      }
    }
  }

  &-pagination {
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    gap: 14px;

    &__icon {
      color: $gray-800;
      font-family: icomoon;
      font-size: 12px;
      width: 12px;
      height: 12px;
      min-width: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: color .15s linear;

      &:hover {
        color: $blue-1;
      }

      &.disabled {
        color: #C5C5C5;
        pointer-events: none;
      }
    }

    &__item {
      color: $gray-800;
      font-family: $font-primary;
      font-size: 16px;
      line-height: 24px;
      transition: color .15s linear;

      &:hover {
        color: $blue-1;
      }

      &.active {
        color: $blue-1;
        pointer-events: none;
      }
    }
  }

  &-post {
    width: 100%;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $gray-200;
    box-sizing: border-box;
    padding: 34px;

    &__headline {
      width: 100%;
      display: flex;
      gap: 24px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      color: $gray-800;
    }

    &__date {
      margin-left: auto;
      display: flex;
      gap: 6px;
      white-space: nowrap;
      font-size: 16px;
      line-height: 1.4;
      color: $gray-800;

      &--label {
        color: $gray-400-2;
      }
    }

    &__about {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 38px;

      &-data {
        cursor: pointer;
        color: $gray-500;
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
      }
    }

    &__check {
      &-field {
        display: none;

        &:checked ~ .ph-post__check-label:after {
          opacity: 1;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        color: $blue-1;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        white-space: nowrap;
        @media (max-width: 667px) {
          font-size: 12px;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 18px;
          min-width: 18px;
          height: 18px;
          border: 1px solid $blue-1;
          border-radius: 2px;
        }

        &:after {
          content: '\e92e';
          font-family: icomoon;
          display: inline-flex;
          width: 15px;
          height: 15px;
          font-size: 15px;
          color: $blue-1;
          line-height: 1;
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity .1s linear;
        }
      }
    }

  }

  &-msg {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    &--replay{
      padding-top: 16px;
      display: none;
    }
    &--main{
      box-sizing: border-box;
      padding: 24px;
      border-radius: 4px;
      border: 1px solid $gray-300;
      background-color: $gray-100;
    }

    &-card{
      width: 100%;
      box-sizing: border-box;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border: 1px solid $gray-300;
      border-radius: 4px;

      &__inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
      }
      &__headline{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__title{
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $blue-dark;
      }
      &__date{
        font-size: 12px;
        color: $gray-400-2;
      }
      &__text-data{
        margin-top: -8px;
        gap: 20px;
        p, ul, ol{
          font-size: 14px;
          line-height: 20px;
        }
      }
      &__images{
        display: flex;
        flex-direction: column;
        gap: 20px;
        img{
          max-width: 100%;
        }
      }

      &__controls{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 8px;
      }
      &__count{
        width: 100%;
        display: flex;
        gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-300;
        align-items: flex-end;
        &-item{
          cursor: pointer;
          color: $gray-500;
          font-size: 14px;
        }
      }
    }

    &-menu{
      position: relative;
      &:hover{
        .ph-msg-menu__list{
          display: inline-flex;
        }
      }
      &__icon{
        display: inline-flex;
        height: 24px;
        align-items: flex-end;
        line-height: 16px;
        cursor: pointer;
        color: $blue-1;
        font-size: 18px;
      }
      &__list{
        display: none;
        position: absolute;
        top: 20px;
        right: 0;
        padding: 8px 0;
        flex-direction: column;
        background: $white;
        border: 1px solid $gray-300;
        border-radius: 4px;
        z-index: 1;
        gap: 12px;

      }
      &__item{
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 12px;
        font-size: 14px;
        line-height: 20px;
        transition: all .2s linear;
        color: $black-3;

        svg{
          width: 14px;
          height: 14px;
          fill: $black-3;
          transition: all .2s linear;
        }
        &:hover{
          color: $orange;
          svg{
            fill: $orange;
          }
        }
      }
    }

    &__hidden {
      display: none;
    }

    &__img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
    }

    &__data {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid $gray-200;
      background-color: $gray-50;
      padding: 16px;
      &-wrapper{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 16px;
        .ph-msg-list{
          margin-bottom: 0;
          &:not(.open) > .ph-msg{
            display: none;
          }
        }
        &.open{
          .ph-msg--replay{
            padding-top: 16px;
            display: flex;
          }
        }
      }
    }

    &__headline {
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: flex-start;
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &__name {
      font-size: 16px;
      font-weight: 700;
      color: $gray-800;
    }

    &__about {
      font-size: 12px;
      color: $gray-500;
    }

    &__time {
      white-space: nowrap;
      font-size: 12px;
      color: $gray-400-2;
      margin-left: auto;
    }

    &__text {
      font-size: 14px;
      p{
        font-size: 14px;
      }
    }

    &__footer {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &__like-container {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &__like {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      font-family: $font-primary !important;
      gap: 4px;
      font-size: 14px;
      color: $gray-800;

      &:before {
        font-family: icomoon;
        font-size: 16px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $blue-1;
      }
    }

    &__link {
      font-size: 14px;
      color: $blue-dark;
      cursor: pointer;
      transition: color .15s linear;

      &:hover {
        color: $blue-1;
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        align-items: center;
        position: relative;
        @media (max-width: 667px) {
          flex-direction: column;
        }
      }
      &-title, &-title-btn{
        display: block;
        width: 100%;
        height: 43px !important;
        box-sizing: border-box;
        background-color: $white;
        border: 1px solid $gray-300;
        border-radius: 4px;
        box-shadow: none !important;
        outline: none !important;
        font-size: 14px;
        padding: 0 16px;
        line-height: 41px;
      }
      &-title-btn{
        color: $gray-400-2;
      }
    }

    &__btn {
      background-color: $blue-1;
      padding: 16px 54px;
      margin-left: auto;
      transition: opacity .2s linear;

      &:hover {
        opacity: .8;
      }
    }

    &__file {
      input {
        display: none;
      }

      .filepond--drop-label {
        border-radius: 6px;
      }
    }

    &__textarea {
      .cke {
        border: none;
        &_inner{
          background-color: transparent;
        }
        &_top {
          display: flex;
          border: none;
          background-color: transparent;
          padding: 8px 0 24px;
        }

        &_contents {
          border-radius: 4px;
          border: 1px solid $gray-300;
        }

        &_bottom {
          display: none;
        }
      }
    }
    &__form-block{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 58px;
      .cke_top{
        padding: 14px 0;
      }
      .cke_contents{
        height: 139px !important;
      }
      &:not(.active){
        display: none;
      }
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;
      &:not(.open){
       & > .ph-msg:nth-child(n + 2){
          display: none;
        }
      }
    }

    &-link {
      cursor: pointer;
      font-size: 14px;
      color: $blue-1;
      transition: opacity .15s linear;

      &:hover {
        opacity: .8;
      }
    }
  }



  &-modal {
    display: flex;
    width: 100%;
    height: 100dvh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: rgba($black-3, .4);
    backdrop-filter: blur(4px);
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;
    &:not(.active){
      display: none;
    }

    &__inner {
      width: 100%;
      max-width: 520px;
      max-height: calc(100dvh - 30px);
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      box-sizing: border-box;
      padding: 60px 15px 62px 34px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 4px 6px 0 rgba($black, .1), 0 2px 4px 0 rgba($black, .06);
      @media (max-width: 667px) {
        padding: 60px 10px 28px 24px;
      }
    }

    &__delete {
      width: 100%;
      max-width: 520px;
      max-height: calc(100dvh - 30px);
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      box-sizing: border-box;
      padding: 60px 34px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 4px 6px 0 rgba($black, .1), 0 2px 4px 0 rgba($black, .06);
      @media (max-width: 667px) {
        padding: 60px 24px;
      }
    }

    &-close-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $gray-400-2;
      width: 20px;
      height: 20px;
      font-size: 20px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      transition: color .15s linear;
      &:hover{
        color: $gray-800;
      }
    }

    &-title{
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      color: $gray-800;
      text-align: center;
      margin: 0;
      box-sizing: border-box;
      padding-right: 15px;
      @media (max-width: 667px) {
        font-size: 20px;
      }
    }
    &-list{
      width: 100%;
      padding-right: 15px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      @-moz-document url-prefix() {
        scrollbar-color: #C7C7C7 #EDEDED;
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 100px;
        background-color: #EDEDED;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #C7C7C7;
        border-radius: 100px;
      }
    }
    &-card{
      width: 100%;
      display: flex;
      align-items: center;
      gap: 18px;
      box-sizing: border-box;
      padding: 16px 0;
      border-bottom: 1px solid $gray-200;

      &__img{
        width: 53px;
        min-width: 53px;
        height: 53px;
        overflow: hidden;
        border-radius: 50%;
      }

      &__data{
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      &__name{
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $gray-800;
      }
      &__subtitle{
        margin: 0;
        font-size: 14px;
        line-height: 22px;
        color: $gray-800;
      }
    }
  }

  &-setting{
    color: #232323;
    width: 100%;
    max-width: 872px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
    &-block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }
    &-subtitle{
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.c-text{
  color: $black-3;
}
.c-link{
  color: $blue-1;
}
.c-gray-400{
  color: $gray-400-2;
}


.subscribe{
  &-option{
    &:disabled ~ label{
      opacity: .4;
      pointer-events: none;
      color: $gray-600;
    }
  }
  &-select select:disabled{
    opacity: .4;
    pointer-events: none;
  }
}
