.epcp{
  &-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  &-input{
    position: relative;
    &--search{
      width: 30%;
      @media (max-width: 1420px) {
        width: calc(100% - 18rem);
        margin-bottom: 1rem;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    &--select{
      flex-grow: 1;
      width: 100%;
    }
    &--calendar{
      @media (max-width: 1420px) {
        flex-grow: 1;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    input, input:not(input[type=colori]){
      display: block;
      box-sizing: border-box;
      padding: 0 .4rem 0 2.3rem;
      min-height: 2.5rem;
      border-radius: .4rem;
      background-color: $white;
      font-size: .9rem;
      color: $gray-400;
      border: 1px solid $gray-400;
      box-shadow: none;
      outline: none;
      &::placeholder{
        color: $gray-500;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

    &__icon{
      position: absolute;
      left: .7rem;
      top: .7rem;
      font-size: 1.1rem;
      color: $gray-400;
    }
  }

  &-field{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: #fff;
    border: 1px solid #9CA3AF !important;
    outline: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    min-height: 2.5rem;
    font-size: 14px;
    padding: 0 1rem !important;
    color: #9CA3AF !important;
    box-shadow: none !important;
    &::placeholder{
      opacity: 1 !important;
      color: #9CA3AF !important;
    }
    .status{
      margin-right: .5rem;
    }
  }
  &-select{
    position: relative;
    align-self: flex-start;
    &__head{
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 2rem 0 1rem;
      min-height: 2.5rem;
      border-radius: 0.4rem;
      background-color: #fff;
      border: 1px solid #9CA3AF;
      box-shadow: none;
      outline: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      font-size: 14px;
      color: #9CA3AF;
      &:after{
        content: '';
        position: absolute;
        top: 0.6rem;
        right: 0.4rem;
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9L12 16L5 9' stroke='%239CA3AF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        transition: transform .2s linear;
        pointer-events: none;

      }

      .status{
        margin-right: .5rem;
      }
    }

    &__label{
      pointer-events: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }

    &__menu{
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      background-color: $white;
      width: 100%;
      box-sizing: border-box;
      padding: .8rem;
      border-radius: .5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
      min-width: 15rem;
      z-index: 1;
      max-height: 13rem;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background:  #9CA3AF;
      }
    }

    &__data{
      &-all{
        cursor: pointer;
        display: inline-block;
        font-size: .88rem;
        color: $blue-500;
        transition: color .2s linear;
        &:hover{
          color: $blue;
        }
      }
      &-wrapper{
        margin-bottom: 0.63rem;
      }
      &-line{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        input{
          display: none !important;

          &:checked ~ .epcp-select__data-label{
            &:before{
              background-color: #03577E;
              border-color: #03577e;
            }
            &:after{
              opacity: 1;
            }
          }
        }
      }
      &-label{
        display: inline-flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        line-height: 1;
        color: #374151;
        cursor: pointer;
        width: auto;
        text-align: left;
        &:before{
          content: '';
          display: inline-block;
          box-sizing: border-box;
          width: 14px;
          min-width: 14px;
          height: 14px;
          border-radius: 0;
          border: 1px solid #9CA3AF;
          margin-right: .5rem;
          transition: background-color .2s linear;
        }
        &:after{
          content: '';
          display: inline-block;
          width: 0.8rem;
          height: 0.5rem;
          position: absolute;
          left: 1px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.43001 6.99023L9.18001 2.24023C9.52003 1.8999 9.52003 1.3501 9.18001 1C8.84004 0.660156 8.28004 0.660156 7.94002 1L3.81001 5.12988L2.06001 3.37988C1.71999 3.04004 1.15999 3.04004 0.820023 3.37988C0.479996 3.72021 0.479996 4.27002 0.820023 4.62012L3.20003 6.99023C3.30074 7.09082 3.41902 7.16016 3.54451 7.19971C3.63075 7.22705 3.72041 7.24023 3.81001 7.24023C4.03999 7.24023 4.26003 7.16016 4.43001 6.99023Z' fill='%23F9FAFB'/%3E%3C/svg%3E%0A");
          opacity: 0;
          transition: opacity .2s linear;
        }

      }
      &-toggle{
        display: inline-block;
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        background-position: center;
        background-size: 1.3rem;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9L12 16L5 9' stroke='%23D1D5DB' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        transition: transform .2s linear;

        &.open{
          transform: scaleY(-1);
        }
      }
      &-about{
        display: none;
        width: 100%;
        box-sizing: border-box;
        padding: .5rem;
        color: #374151;
        background-color: #374151;
        border-radius: .25rem;
        margin-top: .25rem;
        font-size: .75rem;
        line-height: 1.2;
      }
    }
  }
  &-btn{
    display: block;
    width: 100%;
    height: 41px;
    border-radius: 4px;
    border: 1px solid #02577e;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #02577e;
    text-transform: uppercase;
  }
}
