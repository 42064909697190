

.tagsinput, .tagsinput * {
  box-sizing: border-box
}

.tagsinput {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fff;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #556270;
  border-radius: 2px;
}

.tagsinput.focus {
  border-color: #ccc
}

.tagsinput .tag {
  position: relative;
  background: #F6F6F6;
  display: block;
  max-width: 100%;
  word-wrap: break-word;
  color: #02577E;
  padding: 5px 30px 5px 5px;
  border-radius: 4px;
  margin: 0 5px 5px 0
}


.tagsinput .tag .tag-remove {
  position: absolute;
  background: 0 0;
  display: block;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: #ff6b6b;
  line-height: 30px;
  padding: 0;
  border: 0
}

.tagsinput .tag .tag-remove:after,
.tagsinput .tag .tag-remove:before {
  background: #ff6b6b;
  position: absolute;
  display: block;
  width: 10px;
  height: 2px;
  top: 14px;
  left: 10px;
  content: ''
}

.tagsinput .tag .tag-remove:before {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg)
}

.tagsinput .tag .tag-remove:after {
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg)
}

.tagsinput div {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.tagsinput div input {

}

.tagsinput div input.error {
  //color: #ff6b6b
  background: none;
}

.tagsinput div input::-ms-clear {
  display: none
}

.tagsinput div input::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1
}

.tagsinput div input:-moz-placeholder {
  color: #ccc;
  opacity: 1
}

.tagsinput div input::-moz-placeholder {
  color: #ccc;
  opacity: 1
}

.tagsinput div input:-ms-input-placeholder {
  color: #ccc;
  opacity: 1
}
