@media screen and (max-width: 1200px) {
  .xl-column {
    flex-direction: column;
  }
  .col-xl-6, .col-xl-4{
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .col-md-4 {
    max-width: 50%;
  }

  .col-md-6 {}

  .resources-card--wrapper {
    max-width: 50%;
    width: 100%;

  }

  .laptop-column {
    flex-direction: column;
  }

  .lap-w-100 {
    max-width: 100%;
    width: 100%;
  }

  .lap-p-0 {
    padding: 0;
  }

  .mt-lg-30{
    margin-top: 30px;
  }

  .dashboard-cards{
    .content-block{
      .discussion-list{
        .discussion-item{
          .discussion-desc{
            .discussion-title{
              white-space: normal;
              font-size: 14px;
              line-height: 1.2;
            }
            .discussion-text{
              white-space: normal;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
  .dashboard-cards-inner{
    width: 100%;
    max-width: 100%;
    margin-bottom: 42px;

    &:after{
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 992px) {
  .content-right-block{
    width: 100%;
  }

  .main-block {
    padding: 0 20px;
  }

  .logo-wrapper {
    margin-right: 10px;
     img {
       padding: 10px;
       height: 50px !important;
     }
  }

  #search_bar {
    margin: 0;
  }

  .mobile-visible {
    display: flex;
  }

  body {
    &.open {
      position: fixed;
      width: 100%;
    }
  }

  header {
    .img-wrapper {
      margin-right: 0;
    }

    .search-wrapper {
      .m-4 {
        margin-left: 0;
      }
    }

    .mobile-btn--wrapper {
      width: auto;
    }

    #nav-mobile-content {
      height: calc(100vh - 64px);
      background: #fff;
      border-top: 2px solid #bab8b8;

      .mr-3 {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        margin-right: 0 !important;
        padding: 10px 0;

        & > div {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .group {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .img-wrapper {
            padding: 10px 0;
          }

          .absolute {
            width: 100%;
            visibility: visible;
            position: static;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
        }

        .profile-menu {
          display: flex;
          border-bottom: 1px solid #bab8b8;;

        }

      }
    }
  }

  .bound-container {
    padding-left: 1em;
    padding-right: 1em;
  }

  .main-container {
    position: relative;
  }

  .desktop-visible {
    display: none !important;
  }

  .aside-block {
    padding: 10px;
    box-sizing: border-box;

    .sticky {
      position: static;
    }
  }

  .popular-categories {
    position: fixed;
    right: -120%;
    top: 64px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    background: #fff;
    padding: 10px;
    z-index: 9999;
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 64px);
    min-width: auto;
    box-sizing: border-box;


    &.open {
      right: 0;
      transition: all 0.3s;
    }

    .big-heading {
      text-align: center;
    }
  }

  .popular-icon {
    position: fixed;
    right: 15px;
    top: 76px;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    border: 2px solid #000;
    background: #fff;
    z-index: 9;

    img {
      display: flex;
      margin: auto;
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    &.open {
      z-index: 9999;
    }
  }
  .aside-block {
    position: fixed;
    left: -120%;
    top: 64px !important;
    height: calc(100vh - 64px);
    overflow-y: auto;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    background: #fff;
    padding: 10px;
    z-index: 99;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;

    &.open {
      left: 0;
      transition: all 0.3s;
    }
  }
  .aside-icon {
    position: fixed;
    left: 15px;
    right: auto;
    top: 74px;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    border: 2px solid #000;
    background: #fff;
    z-index: 999;
    transition: transform 0.3s;

    img {
      display: flex;
      margin: auto;
      width: 30px;
      height: 30px;
      object-fit: contain;

    }

    &.open {
      left: -25px;
      right: auto;
      transition: transform 0.3s;
      transform: translateX(100vw) translateX(-30px);

      img {
        transform: rotate(-180deg);
      }
    }
  }
  .new-post--wrapper {
    padding: 10px;

    .avatar-wrapper {
      max-width: fit-content;
    }

    .bottom-block {
      flex-direction: column;

      & > div:nth-child(1) {
        margin-bottom: 8px;
      }
    }

    &.open {
      flex-direction: column;
    }
  }
  .comment-card {

    .list-item {
      .inner {
        padding: 10px;
      }
    }


    .top-block {
      flex-wrap: wrap;

      .follow-block {
        width: 100%;
        margin-top: 10px !important;
        justify-content: flex-end;
      }
    }
  }


  //  Resources

  .related-resources {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .tablet-column {
    flex-direction: column;
  }

  .t-p-0 {
    padding: 0 !important;
  }

  .t-w-100 {
    width: 100% !important;
    max-width: 100%;
  }

  .fun-background h1 {
    text-align: center;
  }

  .tab-pl-0 {
    padding-left: 0;
  }

}

@media screen and (max-width: 768px) {
  .mobile-column {
    flex-direction: column!important;
  }
  .col-md-6,
  .col-md-4,
  .col-lg-6{
    max-width: 100%;
    width: 100%;
  }

  .big-heading-wrapper{
    flex-direction: column;
  }

  .main-block {
    padding: 0 10px;
  }

  .resources-card--wrapper {
    max-width: 100%;
    width: 100%;
  }

  .m-mb-20 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .new-updates{
    .testimonials{
      .testimonials--inner{
        flex-direction: column;
        padding-bottom: 32px;
        .gradient-background{
          margin: 0 auto 24px;
        }
        .content-block .news-title{
          height: 18px;
          -webkit-line-clamp: 1;
        }
      }
      .slick-dots{
        left: 50%;
        transform: translateX(-50%);
      }
      .slick-prev{
        left: calc(50% - 60px)!important;
        transform: translate(-50%, -50%);
      }
      .slick-next{
        left: calc(50% + 60px)!important;
        transform: translate(-50%, -50%);
      }
    }
  }
  .card-grow{
    .left-block{
      width: 100%;
      order: -1;
      margin-bottom: 20px;
      img{
        width: 100%;
      }
    }
    .right-block{
      margin: 0;
      max-width: 100%;
    }
  }

}
