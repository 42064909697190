.evn {
  &-btn a{
    text-decoration: none;
    outline: none;
    color: $white;
    box-shadow: none;
    border: none;
    border-radius: 4px;
    background-color: $blue-1;
    line-height: 1;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 4px 16px;
    min-width: 152px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    width: auto;
    @media (max-width: 564px) {
      min-height: 32px;
      font-size: 14px;
    }
  }

  &-hero {
    min-height: 400px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    padding: 62px 42px;
    @media (max-width: 564px) {
      padding: 24px 15px;
      min-height: 300px;
    }

    &--center {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 70px;
      @media (max-width: 564px) {
        padding: 24px 15px;
      }

      .evn-hero {
        &__data {
          max-width: 100%;
          flex-direction: column;
          align-items: center;
        }

        &__subtitle {
          font-size: 30px;
          @media (max-width: 564px) {
            font-size: 18px;
          }
        }

        &__title {
          color: $white;
          font-size: 60px;
          @media (max-width: 564px) {
            font-size: 30px;
          }
        }
      }
    }

    &__data {
      max-width: 320px;
    }

    &__date {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 12px;
      color: $blue-1;
      line-height: 1;

      &-date {
        display: inline-flex;
        align-items: center;

        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          margin-right: 6px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.9 7H14M3.2 1.4V4.2M6 1.4V4.2M8.9 1.4V4.2M11.7 1.4V4.2M0.9 2.8V14H14V2.8H0.9Z' stroke='%2302577E' stroke-width='0.9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }

    &__subtitle {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: $orange;
      @media (max-width: 564px) {
        font-size: 14px;
      }
    }

    &__title {
      margin: 0 0 12px;
      line-height: 1.15;
      color: $blue-1;
      font-weight: 600;
      font-size: 40px;
      @media (max-width: 564px) {
        font-size: 24px;
      }
    }

    &__about {
      max-width: 318px;
      color: $gray-400;
      margin-bottom: 20px;
    }
  }

  &-tab {
    width: 100%;
    padding-top: 26px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: -1;

    &.active {
      opacity: 1;
      pointer-events: auto;
      position: relative;
      z-index: 0;
    }

    &-body {
      position: relative;
    }

    &-list {
      padding-top: 26px;
      margin-bottom: 30px;
    }

    &-head {
      display: flex;
      width: 100%;
      padding-bottom: 8px;
      border-bottom: 1px solid $blue-light-3;
    }

    &-link {
      font-size: 20px;
      font-weight: 400;
      color: $gray-text;
      display: inline-flex;
      margin-right: 27px;
      cursor: pointer;
      transition: color .2s linear;
      @media (max-width: 564px) {
        margin-right: 12px;
        font-size: 14px;
      }
      @media (max-width: 564px) {
        font-size: 11px;
      }

      &:hover {
        color: $blue-1;
      }

      &.active {
        font-family: $font-secondary;
        position: relative;
        color: $blue-1;
        font-weight: 600;

        &:after {
          pointer-events: none;
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          border-radius: 2px;
          background-color: $blue-1;
          position: absolute;
          left: 0;
          top: calc(100% + 7px);
        }
      }
    }

    &__title {
      font-family: $font-secondary;
      font-weight: 700;
      margin: 0 0 2px;
      color: $gray-400;
      font-size: 24px;
      @media (max-width: 564px) {
        font-size: 18px;
      }
    }

    &__about {
      font-weight: 400;
      max-width: 860px;
      font-size: 14px;
      color: $gray-400;
      margin: 0 0 38px;
      @media (max-width: 564px) {
        margin: 0 0 24px;
      }
      p{
        margin: 0;
        font-size: 14px;
      }
      a{
        color: $blue-1;
        font-size: 14px;
      }
    }

    &__btn {
      a{
        font-size: 12px;
        background-color: #02577E !important;

        &:hover{
          background-color: #0374A8 !important;
        }
      }
      &-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;

        &--start {
          justify-content: flex-start;
          margin-bottom: 40px;
        }
      }
    }

    &__conf {
      display: flex;
      justify-content: space-between;
      @media (max-width: 564px) {
        flex-direction: column;
        gap: 14px;
      }

      &-image {
        width: 215px;
        @media (max-width: 564px) {
          width: 100%;
        }
      }

      &-data {
        width: calc(100% - 215px);
        max-width: 740px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 564px) {
          width: 100%;
        }
      }

      &-about {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-title {
        font-size: 20px;
        font-family: $font-secondary;
        font-weight: 700;
        color: $blue-1;
        @media (max-width: 564px) {
          font-size: 16px;
        }
      }

      &-link {
        color: #0891B2;
        text-decoration: underline !important;
        font-size: 14px;

        &:hover {
          color: #0891B2;
        }
      }
    }

    &__alert {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      color: $blue-1;


      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $blue-1;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      @media (max-width: 564px) {
        font-size: 15px;
        max-width: 277px;
      }

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
    }

    &__slider {
      margin-bottom: 30px;
      box-sizing: border-box;
      padding: 0 17px;
      position: relative;
      overflow: hidden;
      display: flex;
      gap: 26px;

      &-item {
        box-sizing: border-box;
        padding: 0 13px;
      }

      .slick-list {
        min-width: 100%;
      }
      .slick-track{
        margin-left: 0;
      }
      .slick-prev, .slick-next {
        top: calc(50% - 10px);
        background-color: transparent;

        &:before {
          color: #9CA3AF;
          font-size: 34px;
        }

        &:hover:before {
          color: $blue-1;
        }
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 0;
      }
    }
  }

  &-card {
    width: 100%;
    background-color: $white;
    border: 1px solid $blue-light-2;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px 18px 25px;

    &--fill {
      border-color: $blue-1;
      overflow: hidden;
      padding-top: 0;

      .evn-card__head {
        border: none;
        background-color: $blue-1;
        padding-top: 16px;
        padding-left: 18px;
        padding-right: 18px;
        box-sizing: border-box;
        margin-left: -18px;
        margin-right: -18px;
      }

      .evn-card__title {
        color: $white;
      }
    }

    &__date {
      display: flex;
      align-items: center;
      color: $orange;
      font-weight: 500;
      font-size: 14px;
      margin: 0 0 15px;

      &-wrapper {
        padding: 7px 10px;
        border-radius: 4px;
        background-color: $gray-bg;
      }
    }

    &__title {
      color: $blue-1;
      font-size: 18px;
      line-height: 1.15;
      letter-spacing: .25px;
      overflow: hidden;
      height: 60px;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin: 0;
      font-weight: 400;
      @media (max-width: 564px) {
        font-size: 16px;
        height: 54px;
      }
    }

    &__head {
      padding-bottom: 12px;
      border-bottom: 1px solid $blue-light-3;
      margin-bottom: 15px;

      &--wl {
        border: none;
        margin: 0;
      }
    }

    &__link {
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      margin-right: 4px;
      color: $blue-1;
      transition: opacity .2s linear;

      &:hover {
        opacity: .7;
      }

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        border-left: 1px solid $blue-1;
        border-bottom: 1px solid $blue-1;
        transform: rotate(-45deg) translate(4px, 2px);

      }
    }

    &__about {
      font-size: 14px;
      color: #1F2937;
      margin: 0 0 25px;
      height: 144px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }

    &__btn {
      min-height: 30px;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      text-decoration: none;
      outline: none;
      color: $white;
      box-shadow: none;
      border: none;
      border-radius: 4px;
      background-color: $blue-1;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 4px 16px;
      cursor: pointer;
      @media (max-width: 564px) {
        min-height: 32px;
        font-size: 14px;
      }

      &.locked {
        background-color: $gray-text;
      }
    }
  }

  &-dot {
    width: 5px;
    min-width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    background-color: $blue-1;
  }

  &-contact {
    margin: 30px auto 60px;
    max-width: 366px;
    text-align: center;
    line-height: 1.25;
    font-size: 16px;

    @media (max-width: 564px) {
      margin: 26px auto 38px;
    }

    &__link, a {
      color: $blue-1;
      font-weight: 700;
    }
  }

  &-gallery {

    &__title {
      font-family: $font-secondary;
      font-size: 24px;
      margin: 0 0 22px;
      text-align: center;
      color: $gray-400;
      @media (max-width: 564px) {
        font-size: 20px;
        margin: 0 0 16px;
      }
    }

    &__slider {
      overflow: hidden;
      display: flex;
      gap: 60px;
      position: relative;
      padding-bottom: 28px;

      &-item {
        box-sizing: border-box;
        transition: padding .1s linear;
        @media (min-width: 768px) {
          padding: 28px 50px;
        }
      }
      .slick-dots {
        position: absolute;
        bottom: 0;
        background-color: transparent;
        padding: 0;
      }

      .image {
        transition: transform .18s linear;
      }

      .slick-center .image {
        @media (min-width: 768px) {
          transform: scale(1.35);
        }
      }

      .slick-prev, .slick-next {
        z-index: 3;
        top: calc(50% - 10px);
        background-color: transparent;

        &:before {
          color: #9CA3AF;
          font-size: 34px;
        }

        &:hover:before {
          color: $blue-1;
        }
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 0;
      }

      .slick-list {
        padding: 0 !important;
      }

      .slick-dots button {
        width: 6px;
        height: 6px;
      }
    }
  }
}

.hover-blue {
  background-color: #02577E !important;

  &:hover{
    background-color: #0374A8 !important;
  }
}

