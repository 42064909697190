.custom-dropdown {
  display: inline-block;
  position: relative;
  line-height: 40px;
}

.dropdown-toggle {
  width: 100%;
  padding: 6px 36px 6px 10px;
  border: 1px solid #9ca3af;
  border-radius: 4px;
  background-color: #fff;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 20 18" fill="none"><path d="M15.8327 6.51953L9.99935 11.4779L4.16602 6.51953" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat right 10px center;
  background-size: 20px 17px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 90%;
  left: 0;
  line-height: normal;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px #00000026;
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-menu.show {
  display: block;
}

.custom-dropdown.open .dropdown-toggle {
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 20 18" fill="none"><path d="M4.16602 11.4779L9.99935 6.51953L15.8327 11.4779" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat right 10px center;
  background-size: 20px 17px;
}

.empty-dropdown-error {
  border: 2px solid #ef5753
}
