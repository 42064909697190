.ck{
  &.ck-sticky-panel__content{
    border: none !important;

  }
  &.ck-powered-by{
    display: none !important;
  }
  &.ck-toolbar{
    padding-left: 0 !important;
    padding-bottom: 24px !important;
  }
  &.ck-content {
    border-color: $gray-300 !important;
    box-shadow: none !important;
    min-height: 96px;
    border-radius: 4px;
    p, li {
      font-size: 14px;
      margin: 0;
      line-height: 1.3;
      font-family: $font-primary;
    }
  }

}
