.a{
  &-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 56px 80px 92px;
    gap: 12px;
    @media (max-width: 910px) {
      padding: 20px 16px 32px;
    }
    @media (max-width: 600px) {
      padding: 20px 16px;
      gap: 32px;
    }
    &--sm{
      min-height: 75vh;
      padding-bottom: 40px;
    }
  }
  &-logo{
    width: 136px;
    height: 36px;
  }
  &-content{
    display: flex;
    gap: 90px;
    align-items: center;
    width: 100%;
    max-width: 1135px;
    margin: auto;
    @media (max-width: 910px) {
      gap: 24px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      max-width: 500px;
      margin-top: 0;
    }
  }
  &-social{
    &-container{
      width: 45%;
      max-width: 502px;
      min-width: 310px;
      background-color: $white;
      box-shadow: 0 4px 40px 0 rgba($black-2, .2);
      box-sizing: border-box;
      padding: 56px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      text-align: center;
      @media (max-width: 600px) {
        width: 100%;
        padding: 32px 15px;
        min-width: 200px;
      }
    }
    &-head{
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
    }
    &-btn{
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: .2px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px 32px;
      height: 64px;
      min-width: 208px;
      border-radius: 4px;
      color: $white;
    }
  }
  &-headline{
    font-size: 24px;
    font-weight: bold;
    color: #034E71;
    margin: 0;
  }
  &-text, &-link{
    font-size: 16px;
    color: $gray-800;
    margin: 0;
    width: auto;
  }
  &-link:hover{
    text-decoration: underline !important;
  }
  &-form{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 600px) {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
    }
    &-fields{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &-input-wrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;
    label{
      font-size: 16px;
      color: $gray-800;
    }
    input {
      &:not([type=checkbox]) {
        height: 40px !important;
        padding: 0 16px !important;
        outline: none !important;
        box-shadow: none !important;
        font-size: 14px;
        color: #111827 !important;
        background-color: transparent !important;

        &.error {
          border-color: #DF2800;
          background-color: transparent;
        }

      }
      &[type=checkbox]{
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100%;
        margin: 0;
        cursor: pointer;
      }
      & ~ label{
        display: inline-flex;
        align-items: center;
        gap: 8px;
        line-height: 1;
        &:before{
          content: '';
          font-family: icomoon;
          color: $blue-500;
          font-size: 18px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          min-width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 1px solid $blue-500;
        }
      }
      &:checked ~ label:before{
        content: '\e92e';
      }
    }
    .flex{
      align-self: flex-start;
      align-items: center;
      position: relative;
    }
  }
}

.nav-dropdown{
  display: flex;
  flex-direction: column;
  position: relative;
  &.active .nav-dropdown{
    &__menu-wrapper{
      opacity: 1;
      pointer-events: auto;
      @media (max-width: 991px) {
        height: auto;
      }
    }
    &__btn:after{
      transform: rotate(90deg);
    }
  }
  &__btn{
    color: #1F2937;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 15px;
    cursor: pointer;
    gap: .5rem;
    @media (max-width: 991px) {
     padding: 0;
    }
    &:after{
      content: '\e90e';
      font-family: icomoon;
      font-size: 12px;
      width: 12px;
      height: 12px;
      min-width: 12px;
      transform: rotate(270deg);
      transition: transform .15s linear;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__menu{
    width: 498px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0 , 0, .25);
    background-color: $white;
    display: flex;
    gap: 50px;
    box-sizing: border-box;
    padding: 24px 22px;
    text-align: left;
    @media (max-width: 991px) {
      flex-direction: column;
      gap: 14px;
      background-color: transparent;
      box-shadow: none;
      padding: 16px 18px 0;
      border-radius: 15px;
      width: 230px;
    }

    &-wrapper{
      padding-top: 15px;
      position: absolute;
      top: 100%;
      left: 8px;
      opacity: 0;
      pointer-events: none;
      transition: opacity .2s linear;
      @media (max-width: 991px) {
        position: static;
        height: 0;
        padding: 0;
      }
    }
  }
  &__col{
    display: inline-flex;
    flex-direction: column;
    width: calc(50% - 25px);
    gap: 14px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  &__item{
    color: #1F2937;
    cursor: pointer;
    transition: color .15s linear;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &:hover{
      color: $blue-1;
      .nav-dropdown__item-description{
        color: $blue-1;
      }
    }

    &-title{
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      font-weight: 700;
    }
    &-description{
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      font-style: italic;
      color: #8C8A8A;
    }
  }
}

.fz-15{
  font-size: 15px;
}
.or-1{
  order: 1;
}

.d-inline-block{
  display: inline-block;
}
.text-grey-800{
  color: #1F2937;
}
