@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/iconFonts/icomoon.eot?s22ctv');
  src:  url('./fonts/iconFonts/icomoon.eot?s22ctv#iefix') format('embedded-opentype'),
  url('./fonts/iconFonts/icomoon.ttf?s22ctv') format('truetype'),
  url('./fonts/iconFonts/icomoon.woff?s22ctv') format('woff'),
  url('./fonts/iconFonts/icomoon.svg?s22ctv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icf"], [class*="icf"], [class*="icon-fss"],  [class*="icon-ep"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icf-library:before {
  content: "\e916";
}

.icf-share:before {
  content: "\e900";
}
.icf-likeO:before {
  content: "\e906";
}
.icf-download:before {
  content: "\e908";
}
.icf-resources:before {
  content: "\e934";
}
.icf-love:before {
  content: "\e901";
}
.icf-mailY:before {
  content: "\e902";
}
.icf-filter:before {
  content: "\e903";
}
.icf-notoficationO:before {
  content: "\e904";
}
.icf-liked:before {
  content: "\e905";
}
.icf-comment:before {
  content: "\e907";
}
.icf-finance:before {
  content: "\e909";
}
.icf-managment:before {
  content: "\e90a";
}
.icf-contact:before {
  content: "\e90b";
}
.icf-events:before {
  content: "\e90c";
}
.icf-chevronR:before {
  content: "\e90d";
}
.icf-chevronL:before {
  content: "\e90e";
}
.icf-chevronL-back:before {
  content: "\e917";
}
.icf-likei:before {
  content: "\e90f";
}
.icf-news:before {
  content: "\e910";
}
.icf-discussion:before {
  content: "\e939";
}
.icf-dashboard:before {
  content: "\e913";
}
.icf-notofication:before {
  content: "\e914";
}
.icf-magnify:before {
  content: "\e915";
}

//new icons
.icon-fss-feedback-bold:before {
  content: "\e939";
}
.icon-fss-arrow-back:before {
  content: "\e917";
}
.icon-fss-arrow-next:before {
  content: "\e918";
}
.icon-fss-asministration:before {
  content: "\e919";
}
.icon-fss-bell:before {
  content: "\e91a";
}
.icon-fss-budgeting:before {
  content: "\e91b";
}
.icon-fss-chat:before {
  content: "\e91c";
}
.icon-fss-credit:before {
  content: "\e91d";
}
.icon-fss-data:before {
  content: "\e91e";
}
.icon-fss-debt:before {
  content: "\e91f";
}
.icon-fss-download:before {
  content: "\e920";
}
.icon-fss-education:before {
  content: "\e921";
}
.icon-fss-education-planning:before {
  content: "\e922";
}
.icon-fss-emoji-happy:before {
  content: "\e923";
}
.icon-fss-enrollment:before {
  content: "\e924";
}
.icon-fss-feedback:before {
  content: "\e925";
}
.icon-fss-filter:before {
  content: "\e926";
}
.icon-fss-flag:before {
  content: "\e927";
}
.icon-fss-flag-outline:before {
  content: "\e928";
}
.icon-fss-heart:before {
  content: "\e929";
}
.icon-fss-info:before {
  content: "\e92a";
}
.icon-fss-mail:before {
  content: "\e92b";
}
.icon-fss-marketing:before {
  content: "\e92c";
}
.icon-fss-menu:before {
  content: "\e92d";
}
.icon-fss-message:before {
  content: "\e92e";
}
.icon-fss-message-dbl:before {
  content: "\e92f";
}
.icon-fss-multifamily:before {
  content: "\e930";
}
.icon-fss-paper-clip:before {
  content: "\e931";
}
.icon-fss-policies:before {
  content: "\e932";
}
.icon-fss-program:before {
  content: "\e933";
}
.icon-fss-resources:before {
  content: "\e934";
}
.icon-fss-security:before {
  content: "\e935";
}
.icon-fss-share:before {
  content: "\e936";
}
.icon-fss-structure:before {
  content: "\e937";
}
.icon-fss-taxes:before {
  content: "\e938";
}
.icon-library:before {
  content: "\e916";
}
.icon-cf-share:before {
  content: "\e900";
}
.icon-cf-love:before {
  content: "\e901";
}
.icon-cf-mailY:before {
  content: "\e902";
}
.icon-cf-filter:before {
  content: "\e903";
}
.icon-cf-notoficationO:before {
  content: "\e904";
}
.icon-cf-liked:before {
  content: "\e905";
}
.icon-cf-likeO:before {
  content: "\e906";
}
.icon-cf-comment:before {
  content: "\e907";
}
.icon-cf-download:before {
  content: "\e908";
}
.icon-cf-finance:before {
  content: "\e909";
}
.icon-cf-managment:before {
  content: "\e90a";
}
.icon-cf-contact:before {
  content: "\e90b";
}
.icon-cf-events:before {
  content: "\e90c";
}
.icon-cf-chevronR:before {
  content: "\e90d";
}
.icon-cf-chevronL:before {
  content: "\e90e";
}
.icon-cf-likei:before {
  content: "\e90f";
}
.icon-cf-news:before {
  content: "\e910";
}
.icon-cf-discussion:before {
  content: "\e911";
}
.icon-cf-resources:before {
  content: "\e912";
}
.icon-cf-dashboard:before {
  content: "\e913";
}
.icon-cf-notofication:before {
  content: "\e914";
}
.icon-cf-magnify:before {
  content: "\e915";
}


.icon-fss-analyze:before {
  content: "\e93a";
}
.icon-fss-book:before {
  content: "\e93b";
}
.icon-fss-calendar:before {
  content: "\e93c";
}
.icon-fss-collect:before {
  content: "\e93d";
}
.icon-fss-exclamation:before {
  content: "\e93e";
}
.icon-fss-folder:before {
  content: "\e93f";
}
.icon-fss-folder-open:before {
  content: "\e940";
}
.icon-fss-layers:before {
  content: "\e941";
}
.icon-fss-newspaper:before {
  content: "\e942";
}
.icon-fss-note:before {
  content: "\e943";
}
.icon-fss-remove:before {
  content: "\e944";
}
.icon-fss-video:before {
  content: "\e945";
}
.icon-fss-book-open:before {
  content: "\e946";
}
.icon-fss-data-service:before {
  content: "\e947";
}
.icon-fss-pm:before {
  content: "\e948";
}
.icon-ep-arrow:before {
  content: "\e949";
}
.icon-ep-heart:before {
  content: "\e94a";
}
.icon-ep-settings:before {
  content: "\e94b";
}
.icon-ep-user-2:before {
  content: "\e94c";
}
.icon-ep-swap:before {
  content: "\e94d";
}
.icon-ep-chat:before {
  content: "\e94e";
}
.icon-ep-dots:before {
  content: "\e94f";
}
.icon-ep-user:before {
  content: "\e950";
}
.icon-fss-arrow-bottom:before {
  content: "\e951";
}
.icon-fss-arrow-down:before {
  content: "\e952";
}
.icon-fss-arrow-top:before {
  content: "\e953";
}
.icon-fss-arrow-up:before {
  content: "\e954";
}
.icon-fss-lock:before {
  content: "\e955";
}
.icon-fss-lens:before {
  content: "\e956";
}
.icon-fss-hide:before {
  content: "\e957";
}
.icon-fss-info-2:before {
  content: "\e958";
}
.icon-fss-show:before {
  content: "\e959";
}
.icon-fss-calendar-2:before {
  content: "\e95a";
}
.icon-fss-delete:before {
  content: "\e95b";
}
.icon-fss-upload:before {
  content: "\e95c";
}
.icon-fss-usd:before {
  content: "\e95d";
}
.icon-fss-grid:before {
  content: "#";
}
.icon-fss-chart:before {
  content: "\e95e";
}
.icon-fss-eye:before {
  content: "\e95f";
}
.icon-fss-error:before {
  content: "\e960";
}
.icon-fss-success:before {
  content: "\e961";
}
.icon-fss-people:before {
  content: "\e962";
}
.icon-fss-update:before {
  content: "\e963";
}
.icon-fss-help:before {
  content: "\e964";
}
.icon-fss-plus:before {
  content: "\e965";
}
