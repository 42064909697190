.hc{
  &-container{
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  &-block{
    display: flex;
    flex-direction: column;
    gap: 34px;
  }
  &-form{
    padding-top: 60px;
  }

  &-card{
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-line;
    &__head{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 24px;
      cursor: pointer;
      &:after{
        content: '\e965';
        font-family: icomoon;
        font-size: 24px;
        color: $gray-800;
        line-height: 1;
        display: inline-flex;
        width: 24px;
        min-width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
      }
    }
    &__title{
      padding-top: 8px;
      color: $gray-800;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
    }
    &__body{
      display: none;
      color: $gray-800;
      font-size: 16px;
      padding-top: 8px;
    }
    &-list{
      border-top: 1px solid $gray-line;
    }
  }

  &-title{
    text-align: center;
    margin: 0 auto;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 24px;
    color: $gray-800;
  }
  &-headline{
    margin: 0;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 24px;
    color: $gray-800;
  }

  &-tab{
    margin-top: -10px;
    &__head{
      display: flex;
      gap: 32px;
      padding-bottom: 4px;
      border-bottom: 1px solid $gray-line;
    }

    &-btn{
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      color: $gray-800;
      font-size: 20px;
      font-weight: 400;

      &.active{
        pointer-events: none;
        color: $blue-1;
        font-family: $font-secondary;
        font-weight: 700;
        &:after{
          content: '';
          display: inline-block;
          width: calc(100% + 4px);
          height: 3px;
          border-radius: 3px;
          background-color: $blue-1;
          position: absolute;
          left: -2px;
          bottom: -6px;
        }
      }
    }

    &-item{
      &:not(.active){
        display: none;
      }
    }
  }
}
