.co {
  &-controls {
    display: flex;
    gap: 20px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    @media (max-width: 1399px) {
      justify-content: flex-end;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 10px;
      margin-left: auto;
      @media (max-width: 1399px) {
        order: -1;
        width: 100%;
      }
    }

    &-select {
      width: 100%;
      max-width: 360px;
      @media (max-width: 800px) {
        max-width: 348px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        .drdt-list-select{
          max-width: 100%;
        }
      }
    }

    &-btns {
      display: flex;
      gap: 20px;
      @media (max-width: 500px) {
        width: 100%;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  &-link {
    width: auto;
    display: flex;
    align-items: center;
    gap: 5px;
    color: $black;
    transition: color .2s linear;
    line-height: 1.15;

    &:hover {
      color: $blue-1;
    }

    &:before {
      content: '\e90e';
      font-family: icomoon;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      height: 9px;
      width: 9px;
      min-width: 9px;
    }
  }
  &-tabs{
    position: relative;
  }
  &-tab {
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    gap: 25px;
    &:not(.active){
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    &-head {
      width: 100%;
      display: flex;
      gap: 15px;
      border-bottom: 1px solid $blue-dark;
      @media (max-width: 500px) {
        flex-direction: column;
        gap: 0;
      }
    }

    &-link {
      min-height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 6px 23px;
      border-radius: 8px 8px 0 0;
      box-sizing: border-box;
      font-weight: 700;
      font-size: 18px;
      color: $blue-dark;
      background-color: $gray-200;

      cursor: pointer;
      transition: background-color .2s linear;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 16px;
        border-radius: 0;
        border-left: 1px solid $blue-dark;
        border-right: 1px solid $blue-dark;
        border-top: 1px solid $blue-dark;

        &:first-child{
          border-radius: 8px 8px 0 0;
        }
      }

      &:hover {
        background-color: $blue-light;
      }

      &.active {
        color: $white;
        background-color: $blue-dark;
        pointer-events: none;
      }
    }

    &-block {
      box-sizing: border-box;
      padding: 40px;
      background-color: $white;
      border: 1px solid $blue-dark;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width: 500px) {
        padding: 28px 10px 12px;
      }
      & > .co-graph{
        flex-grow: 1;
      }
      &--pb {
        padding-bottom: 15px;
      }

      &--sm, &--md, &--lg {
        padding: 40px 30px 12px;
        gap: 0;
        width: 100%;
      }

      @media (max-width: 990px) {
        &--sm, &--md, &--lg {
          min-width: 100%;
        }
      }
      @media (min-width: 991px) {
        &--sm {
          max-width: 307px;
        }
        &--md {
          max-width: 465px;
        }
        &--lg {
          max-width: calc(50% - 10px);
        }
      }
      @media (max-width: 500px) {
        &--sm, &--md, &--lg {
          padding: 28px 10px 12px;
        }
      }


      &__title {
        margin: 0;
        color: $blue-dark;
        text-align: center;
        font-size: 28px;
        font-family: $font-primary;
        font-weight: 400;
        @media (max-width: 800px) {
          font-size: 22px;
        }
      }

      &__row {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
      }

      &__graph {
        min-width: 320px;
        @media (max-width: 990px) {
          margin: 0 auto;
        }
        &-lg {
          @media (max-width: 990px) {
            margin: 0 auto;
            overflow: hidden;
            .co-line{
              overflow: auto hidden;
            }
          }

        }
        @media (min-width: 991px) {
          width: calc(33.33% - 14px);

          &-lg {
            width: 515px;
            min-width: 320px;
          }
          &--sm {
            box-sizing: border-box;
            padding: 0 24px;
            max-width: 320px;
          }
          &-auto {
            width: 100%;
            max-width: 310px;
          }
        }
      }
    }

    &-select {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      &__title {
        display: inline-block;
        font-size: 17px;
        color: $blue-dark;
        white-space: nowrap;
      }

      &__s {
        &-1 {
          width: 175px;
        }

        &-2 {
          width: 149px;
        }

        &-3 {
          //width: 100px;
          width: 130px;
        }

        &-4 {
          width: 87px;
        }
      }

      .drd-select {
        &__value {
          background-color: $white;
          min-height: 36px;
          border: 1px solid $gray-text;
          padding-left: 10px;

          &:after {
            font-size: 12px;
            width: 12px;
            height: 12px;
            right: 12px;
          }

          &-data {
            font-size: 12px;
            color: $gray-800;
          }
        }

        &__menu {
          padding: 6px 0;
        }

        &__item {
          font-size: 12px;
          color: $gray-800;

          &-wrapper {
            padding: 2px 8px;
          }
        }
      }
    }


    &__filters {
      &-row {
        display: flex;
        gap: 20px 15px;
        flex-wrap: wrap;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 20px;
    }

    .drd-select__value{
      padding-right: 25px;
      &-data{
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    #pie-3 .apexcharts-legend{
      padding-bottom: 0;
      padding-right: 0;
      max-width: 146px;
    }
  }

  &-graph {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title {
      color: $gray-text;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-height: 20px;
    }

    &__headline {
      display: flex;
      justify-content: center;
      gap: 24px;
      align-items: flex-start;
    }

    &__icon {
      cursor: pointer;
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $blue-dark;
      transition: color .2s linear;

      &:hover {
        color: $blue-1;
      }

    }

    &__show {
      cursor: pointer;
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $blue-dark;
      transition: color .2s linear;
      font-style: normal;

      &:before {
        content: '\e959';
        font-family: icomoon;
        font-size: 16px;
      }

      &.active {
        color: $gray-text;

        &:before {
          content: '\e957';
        }
      }

      &:hover {
        color: $blue-1;
      }
    }

    &__about {
      display: flex;
      flex-direction: column;
      gap: 3px;

      &-wrapper {
        display: flex;
        gap: 50px;
        align-items: flex-end;
        position: relative;

        &.chart {
          justify-content: flex-end;
          margin-top: -36px;
          max-width: 310px;
        }
      }

      &-row {
        display: flex;
        gap: 5px;
      }

      &-value {
        width: 25%;
        min-width: 100px;
        display: flex;
        justify-content: flex-end;
        color: $blue-dark;
      }

      &-data {
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 12px;
        gap: 5px;
        color: $gray-text;
      }
    }

    &__description {
      font-size: 14px;
      line-height: 22px;
      color: $blue-dark;
    }
  }

  &-info {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover .co-info__description {
      display: inline-block;
    }

    &__icon {
      font-size: 14px;
      cursor: pointer;
      color: #9BA5B1;
    }

    &__description {
      display: none;
      width: 245px;
      position: absolute;
      bottom: 100%;
      right: -10px;
      z-index: 1;
      box-sizing: border-box;
      padding: 10px 14px 10px 6px;
      font-size: 12px;
      color: #1f2937;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
      background-color: #fff;
      border-radius: 5px;
      @media (max-width: 1239px) {
        width: 220px;
        right: -25px;
      }
    }
  }

  &-bar {
    display: flex;
    color: $blue-dark;
    font-size: 12px;

    &__labels {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 8px 8px 8px 0;
      border-right: 1px solid $blue-1;
    }

    &__grid {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
    }

    &__label, &__row {
      display: flex;
      align-items: center;
      height: 47px;
    }

    &__row {
      gap: 10px;
    }

    &__label, &__value {
      white-space: nowrap;
    }

    &__line {
      width: 0;
      height: 16px;
      background: linear-gradient(92.19deg, $blue-dark -9.41%, #4E89A5 77.41%);
    }
  }

  &-line {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: $blue-dark;
    font-size: 11px;
    margin: auto auto 0;
    word-break: break-word;

    &-wrapper {
      overflow: auto;
      &--line{
        overflow: inherit;
        min-height: 174px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .co-line{
          margin: 0;
        }
      }
      @media (min-width: 768px) {


        &::-webkit-scrollbar {
          height: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: $gray-text;
        }
      }
      @media (max-width: 767px) {
        padding-bottom: 10px;
      }
    }

    &--sm {
      .co-line {
        &__grid, &__labels {
          gap: 4px;
          justify-content: flex-start;
        }

        &__labels {
          padding: 0 10px;
          margin: 0 auto;
        }

        &__label, &__col {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 51px;
          min-width: 51px;
        }
      }
    }

    &__labels {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-bottom: 12px;
    }

    &__grid {
      display: flex;
      justify-content: center;
      gap: 20px;
      align-items: flex-end;
      border-bottom: 1px solid $blue-1;
      margin: 0 auto;
      padding: 0 10px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }

    &__label, &__col {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 57px;
    }

    &__col {
      gap: 15px;
    }

    &__value {
      white-space: nowrap;
    }

    &__line {
      width: 16px;
      background: linear-gradient(0, $blue-dark -9.41%, #4E89A5 77.41%);
    }

    &__max {
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% + 26px);
      height: 1px;
      display: flex;
      align-items: center;
      gap: 4px;
      z-index: 1;

      &-value {
        font-size: 12px;
        white-space: nowrap;
        color: $orange;
        display: inline-block;
        line-height: 1px;
      }

      &-line {
        max-width: calc(100% - 26px);
        min-width: calc(100% - 26px);
        background-color: $orange;
        height: 1px;
        flex-grow: 1;
        display: inline-block;
      }
    }
  }

  &-pie {
    &-wrapper {
      margin: 0 auto;
      @media (max-width: 767px) {
        overflow: hidden;
        padding-bottom: 10px;
        max-width: 100%;
      }
    }

    .apexcharts-pie-series path {
      filter: none !important;
    }

    .apexcharts-legend {
      justify-content: center !important;
      padding-bottom: 36px;
      @media (max-width: 500px) {
        right: 28px !important;
      }
    }

    .apexcharts-legend-marker {
      width: 10px !important;
      min-width: 10px !important;
      height: 10px !important;
      border-radius: 1px !important;
      margin-right: 9px !important;
      cursor: default !important;
      @media (max-width: 500px) {
        width: 8px !important;
        min-width: 8px !important;
        height: 8px !important;
        border-radius: 1px !important;
        margin-right: 4px !important;
      }
    }

    .apexcharts-legend-text {
      color: $blue-dark !important;
      font-family: $font-primary !important;
    }
  }

  &-line {
    .apexcharts-yaxis-texts-g {
      display: none !important;
    }

    .apexcharts-text tspan {
      fill: $blue-dark;
    }
  }

  &-alert {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(19, 26, 29, 0.7);
    }

    &__content {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 350px;
      background-color: $white;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 60px 20px 25px;
      display: flex;
      flex-direction: column;
      gap: 29px;
      align-items: center;
    }

    &__value {
      width: 100%;
      max-width: 210px;
      font-size: 18px;
      text-align: center;
      color: $blue-dark;
    }

    &__btn {
      min-height: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: $blue-dark;
      border-radius: 5px;
      color: $white;
      font-weight: 700;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 14px;
      box-sizing: border-box;
      padding: 4px 20px;
      cursor: pointer;
      outline: none;
      border: none;
      box-shadow: none;
      text-decoration: none;
      transition: opacity .2s linear;

      &:hover {
        opacity: .75;
      }
    }

    .drdm-close {
      right: 15px;
      top: 15px;
    }
  }

  &-paral {
    display: flex;
    flex-direction: column;
    width: 54px;
    gap: 8px;
    justify-content: center;

    &-container {
      display: inline-flex;
      gap: 50px;
      justify-content: space-around;
      min-width: 180px;
    }

    &-wrapper {
      display: flex;
      gap: 32px;
      align-items: flex-end;
      justify-content: center;
    }

    &__value {
      white-space: nowrap;
      text-align: center;
      font-size: 16px;
      color: $blue-dark;
      margin-bottom: 24px;
    }

    &__label {
      text-align: center;
      font-size: 14px;
      color: $blue-dark;
    }

    &__col {
      height: 152px;
      width: 54px;
      clip-path: polygon(0 0, 100% 0, 100% 92%, 50% 100%, 0 92%);
      overflow: hidden;
      position: relative;

      &-inner {
        top: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 54 152' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.7'%3E%3Cpath d='M0 144H27L27 152L0 144Z' fill='%234E89A5'/%3E%3Crect y='8' width='27' height='136' fill='%234E89A5'/%3E%3C/g%3E%3Cg opacity='0.6'%3E%3Cpath d='M54 144H27L27 152L54 144Z' fill='%2302577E'/%3E%3Crect width='27' height='136' transform='matrix(-1 0 0 1 54 8)' fill='%2302577E'/%3E%3C/g%3E%3Crect width='28.1603' height='28.1603' transform='matrix(0.958798 0.284088 -0.958798 0.284088 27 0)' fill='%2302415F'/%3E%3C/svg%3E");
      }
    }
  }

  &-gap {
    gap: 24px;
    padding-bottom: 12px;
    @media (max-width: 500px) {
      padding: 28px 10px 12px;
    }
    .co-graph{
      justify-content: flex-end;
    }
  }

  &-form {
    position: relative;

    &:not(.open) .co-form__body {
      display: none;
    }

    &__body {
      position: absolute;
      left: 0;
      top: calc(100% + 5px);
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 10px;
      z-index: 1;
      background-color: $white;
      padding: 10px 20px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }

    &__description {
      font-size: 10px;
      color: $gray-text;
    }

    &__field {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-label {
        color: $blue-dark;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &-input {
        font-size: 12px;
      }
    }

    &__btn {
      min-height: 33px;
      font-size: 12px;
      text-transform: none;

      &-wrapper {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
    }
  }
}

#line {
  min-height: 210px !important;
  .apexcharts-xaxis-texts-g{
    transform: translateY(-2px);
  }
}


.ttX-1 {
  transform: translateX(15px);
  @media (max-width: 500px) {
    transform: translateX(0);
  }
}

.dark-blue {
  color: $blue-dark;
}
