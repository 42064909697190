.form-post{
  position: relative;

  .form-post-btn{
    color: #fff;
    font-size: 16px;
    max-width: 200px;
    min-height: 50px;
    background-color: #02577E;
    margin-bottom: 8px;
  }

  .post-title {
    display: flex;
    align-items: center;

    input {
      max-width: 100%;
      width: 100%;
      height: 42px;
      margin: 0;
      font-size: 16px;
      &::placeholder{
        font-size: 16px;
      }

    }
  }
  .pb-cke{
    padding-bottom: 20px;
  }
  .cke_top{
    position: absolute;
    top: 48px;
    left: 0;
    background: transparent;
    border: none;
    padding: 6px 0 0;
  }
  .cke_bottom{
    display: none;
  }
  .cke_chrome{
    border-radius: 4px;
    overflow: hidden;
    border-color: #E1E1E1;
  }
  .cke_editable{
    font-size: 16px;
    line-height: 1;
  }

  .tags-list .tagsinput{
    padding: 8px 16px 3px;
    border: 1px solid #E1E1E1;
    height: 40px;
    border-radius: 4px;
    .tag {
      background-color: #F3F4F6;
      padding: 8px 30px 8px 8px;
      color: #02577E;
      border-radius: 4px;
      line-height: 1;

      .tag-remove {
        top: 1px;
        &:after, &:before {
          width: 14px;
          border-radius: 2px;
          background-color: #02577E;
        }
      }
    }
  }
  .post-line{
    height: 42px;
  }

  @media (max-width: 1199px) {
    .post-title{
      input{
        width: 100%;
        margin-bottom: 26px;
      }
    }
    .cke_top{
      position: absolute;
      top: 46px;
      left: 0;
    }

    .bottom-block .filepond--root{
      max-width: 100%;
      margin-bottom: 16px;
    }
    .form-post-btn{
      margin-left: auto;
    }
  }

  &--comment{
    padding-top: 44px;
    @media (max-width: 1199px) {
      .cke_top{
        top: 0;
        left: auto;
      }
      .bottom-block .filepond--root{
        max-width: 100%;
        margin-bottom: 16px;
      }
      .form-post-btn{
        margin-left: auto;
      }
    }
  }
}


.show-comment-wrapper .form-post--comment .cke_top{
  top: 0;
}
