.drd {
  &-container {
    width: 100%;
    max-width: 1358px;
    box-sizing: border-box;
    padding: 36px 40px 30px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1239px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-btn {
    min-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: $blue-dark;
    border-radius: 5px;
    color: $white;
    font-weight: 700;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 16px;
    box-sizing: border-box;
    padding: 8px 25px;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    transition: opacity .2s linear;

    &:hover {
      opacity: .75;
    }

    &--lg {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 53px;
    }

    &--disabled {
      pointer-events: none;
      background-color: $gray-300;
      color: $gray-text;
    }

    &--gray {
      background-color: $gray-400-2;
    }

    &--drop {
      text-transform: none;

      &:after {
        content: "\e952";
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 16px;
        width: 24px;
        min-width: 24px;
        height: 24px;
        color: $white;
      }
    }

    @media (max-width: 767px) {
      min-height: 38px;
      font-size: 14px;
      padding: 4px 16px;
    }

  }

  &-head {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-headline {
      width: 100%;
      display: flex;
      gap: 28px;
      justify-content: space-between;
      align-items: flex-end;
    }

    &-title {
      color: $blue-dark;
      font-family: $font-secondary;
      margin: 0;
      font-size: 26px;
    }

    &-link {
      display: inline-flex;
      align-items: center;
      line-height: 1;
      font-size: 16px;
      color: $black;
      text-decoration: none;
      gap: 8px;
      transition: color .2s linear;
      cursor: pointer;
      width: auto;

      &:before {
        content: "\e90e";
        font-family: 'icomoon';
        font-size: 9px;
        display: inline-flex;
        align-items: center;
        min-width: 9px;
        width: 9px;
        height: 9px;
      }

      &:hover {
        color: $blue-dark;
      }
    }
  }

  &-report {
    border: 1px solid $blue-dark;
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    background-color: $white;
    display: flex;
    align-items: center;
    gap: 30px;
    min-height: 140px;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      flex-direction: column;
    }

    &-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      @media (max-width: 991px) {
        width: 100%
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        gap: 12px;
      }
    }

    &-info {
      position: relative;

      &:hover .drd-report-info__description {
        display: block;
        text-transform: none;
      }

      &__icon {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #9BA5B1;
        width: 24px;
        min-width: 24px;
        height: 24px;
        font-size: 24px;
      }

      &__description {
        display: none;
        width: 176px;
        position: absolute;
        top: calc(100% + 24px);
        right: 0;
        z-index: 99;
        box-sizing: border-box;
        padding: 10px 14px 10px 6px;
        font-size: 12px;
        color: $gray-800;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        background-color: $white;
        border-radius: 5px;
        @media (max-width: 767px) {
          width: 182px;
        }
      }
    }

    &-col {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-width: 33.33%;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 4px;
      border-right: 1px solid #4B5563;

      &:last-child {
        border-right: none;
      }

      @media (max-width: 767px) {
        border-right: none;
        width: 100%;
        max-width: 450px;
      }
    }

    &-value {
      font-size: 30px;
      line-height: 38px;
      font-family: $font-secondary;
      color: $blue-dark;

      &--text {
        color: $orange;
        font-size: 20px;
      }
    }

    &-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.21;
      color: $gray-text;
      text-transform: uppercase;
    }

    &-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &-controls {
    margin-bottom: 15px;

    &-headline {
      margin: 0 0 10px;
      font-size: 16px;
      line-height: 1.15;
      text-transform: uppercase;
      color: $blue-dark;
    }

    &-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 1239px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    &-btns {
      display: flex;
      gap: 18px;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        .drd-btn {
          width: 100%;
          max-width: 398px;
        }
      }
    }
  }

  &-select {
    position: relative;

    &__value {
      width: 100%;
      min-height: 50px;
      border-radius: 5px;
      background-color: $gray-200;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      padding: 4px 30px 4px 15px;
      display: inline-flex;
      align-items: center;
      color: $gray-text;
      cursor: pointer;

      &:after {
        content: "\e952";
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 15px;
        width: 15px;
        height: 15px;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }

      &-data {
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        gap: 12px;
        font-size: 18px;

        &.lock:after {
          content: "\e955";
          font-family: icomoon;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-size: 15px;
          width: 15px;
          height: 15px;
        }
      }
    }

    &__menu {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      top: calc(100% + 5px);
      z-index: 1;
      background-color: $white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 10px 0;
      overflow: auto;
      max-height: 362px;
      @media (min-width: 768px) {

        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: $gray-text;

        }
      }
    }

    &__item {
      display: inline-flex;
      gap: 20px;
      align-items: center;
      color: $gray-text;
      font-size: 16px;
      pointer-events: none;

      &.lock:after {
        content: "\e955";
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 15px;
        width: 15px;
        height: 15px;
      }

      &-wrapper {
        box-sizing: border-box;
        padding: 5px 15px;
        cursor: pointer;
        transition: background-color .2s linear;

        &:hover,
        &.selected {

          .drd-select__item {
            position: relative;
            padding-right: 14px;

            &:after {
              position: absolute;
              right: 0;
              bottom: 0;
              font-family: icomoon!important;
              speak: never;
              display: block;
              margin-left: 0;
              content: "\e92e";
              color: #02415f;
              font-size: 14px;
            }
          }

        }
      }
    }

    &-wrapper {
      width: 100%;
      max-width: 398px;
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    &-alert {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 35px 5px 15px;
      background-color: $orange;
      font-size: 12px;
      color: $white;
      margin: 0;
      border-radius: 5px;
    }
  }
}

.drdt {
  position: relative;
  background-color: $white;
  border: 1px solid $gray-400-2;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  @media (max-width: 767px) {
    padding: 24px 12px;
  }

  &-controls {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    @media (max-width: 1239px) {
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 20px;
    }
  }

  &-count {
    display: flex;
    gap: 10px;
    align-items: center;

    &-label {
      color: $gray-text;
      font-size: 16px;
    }

    &-select {
      width: 54px;

      .drd-select {
        &__value {

          min-height: 20px;
          border-radius: 0;
          background-color: transparent;
          padding: 0 18px 0 0;

          &:after {
            font-size: 12px;
          }

          &-data {
            font-size: 16px;
          }
        }

        &__menu {
          max-height: 160px;
        }

        &__item {
          font-size: 12px;
          color: $gray-400;

          &-wrapper {
            box-sizing: border-box;
            padding: 4px;
          }
        }
      }
    }
  }

  &-search {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    background-color: $gray-100;
    color: $gray-text;
    gap: 10px;

    &:before {
      content: "\e956";
      font-family: icomoon;
      font-size: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      width: 32px;
      height: 32px;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 400px;
      margin: 0;
    }

    &-input {
      border: none !important;
      border-left: 1px solid $gray-text !important;
      height: 30px;
      width: 208px;
      box-shadow: none !important;
      border-radius: 0 !important;
      font-size: 16px;
      font-weight: 400;
      opacity: 1;
      color: $gray-text !important;
      box-sizing: border-box;
      padding: 0 0 0 16px;
      background-color: transparent;

      &::placeholder {
        font-weight: 400;
        opacity: 1 !important;
        color: $gray-text !important;
      }
    }
  }

  &-list {
    display: flex;
    align-items: center;
    gap: 15px;
    @media (max-width: 1239px) {
      width: 100%;
    }
    @media (max-width: 1239px) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    &-label {
      color: $gray-text;
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
    }

    &-select {
      position: relative;
      width: 398px;

      &.w-100 {
        width: 100%;
      }

      @media (max-width: 1239px) {
        width: 100%;
        max-width: 398px;
      }

      &__value {
        width: 100%;
        min-height: 50px;
        border-radius: 5px;
        background-color: $gray-100;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        padding: 18px 30px 18px 14px;
        display: inline-block;
        color: $gray-800;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;

        &:after {
          content: "\e952";
          font-family: icomoon;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-size: 15px;
          width: 15px;
          height: 15px;
          pointer-events: none;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }

        &-data {
        }
      }

      &__menu {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 5px);
        z-index: 3;
        background-color: $white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 6px 0;
        overflow: auto;
        max-height: 362px;
        @media (min-width: 768px) {

          &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: $gray-text;

          }
        }
      }

      &__item {
        display: flex;
        gap: 8px;
        align-items: center;
        color: $gray-800;
        font-size: 12px;
        box-sizing: border-box;
        padding: 4px 10px;
        cursor: pointer;
        transition: background-color .2s linear;

        &:hover {
          background-color: $gray-200;
        }

        &:before {
          content: "\e959";
          font-family: icomoon;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          min-width: 12px;
          width: 12px;
          height: 12px;
          color: $blue-2;
        }

        &.lock:before {
          content: "\e957";
          color: $gray-400-2;
        }

        &--lg {
          font-size: 16px;

          &:before {
            font-size: 16px;
            min-width: 16px;
            width: 16px;
            height: 16px;
          }
        }
      }

      &-alert {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 35px 5px 15px;
        background-color: $orange;
        font-size: 12px;
        color: $white;
        margin: 0;
        border-radius: 5px;
      }
    }
  }


  &-table {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      gap: 0px;
    }

    .lock {
      display: none;
    }
  }

  &-head {
    box-sizing: border-box;
    padding: 31px 28px;
    display: flex;
    background-color: $gray-100;
    gap: 8px;
    flex-grow: 1;
    @media (max-width: 767px) {
      padding: 20px 22px;
    }

    &-wrapper {
      overflow: auto;
      display: flex;
      border-radius: 5px;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
      }
    }
  }

  &-th {
    position: relative;
    font-size: 14px;
    color: $gray-800;
    padding: 15px 10px 15px 0;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    border-right: 1px solid $gray-800;

    &--actions {
      margin-left: auto;
      justify-content: flex-end;
    }

    &:last-child {
      padding-right: 0;
      border: none;
    }

    &__value {
      font-size: 14px;
      text-transform: uppercase;
    }

    &__nav {
      display: inline-flex;
      flex-direction: column;
      gap: 6px;

      &-icon {
        font-size: 10px;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }

  &-info {
    position: absolute;
    top: 100%;
    left: 0;

    &:hover .drdt-info__description {
      display: block;
      text-transform: none;
    }

    &__icon {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #687382;
      width: 12px;
      min-width: 12px;
      height: 12px;
      font-size: 12px;
    }

    &__description {
      display: none;
      width: 245px;
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 99;
      box-sizing: border-box;
      padding: 10px 14px 10px 6px;
      font-size: 12px;
      color: $gray-800;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      background-color: $white;
      border-radius: 5px;
      @media (max-width: 767px) {
        width: 182px;
      }
    }
  }

  &-row {
    overflow: auto;
    display: flex;
    transition: box-shadow .2s linear;
    border-radius: 5px;
    border: 1px solid $gray-400-2;
    flex-grow: 1;
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    }

    &-content {
      box-sizing: border-box;
      min-height: 86px;
      padding: 31px 28px;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: $white;
      flex-grow: 1;
      @media (max-width: 767px) {
        padding: 22px 10px;
        min-height: 56px;
      }

      &.alert {
        box-shadow: none;
        border-color: $red-500;

        .drdt-td--actions {
          transform: translateX(-12px);
        }
      }
    }

    &-wrapper {
      &.alert {
        .drdt-row {
          box-shadow: none;
          border-color: $red-500;
        }

        .drdt-td--actions {
          transform: translateX(-12px);
        }
      }
    }

  }

  &-td {
    &--actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      margin-left: auto;
    }

    &__value {
      color: $gray-800;
      font-size: 14px;
    }

    &-error {
      font-size: 7px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 3px;
      color: $red-500;
      margin-right: 3px;

      &:before {
        content: "\e958";
        font-family: icomoon;
        width: 21px;
        min-width: 21px;
        height: 21px;
        font-size: 21px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-scroll {
    overflow: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      width: 0;
      height: 8px;
      background-color: #F4F4F4;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $gray-800;
    }

    &-content {
      font-size: 1px;
      opacity: 0;
      box-sizing: border-box;
      padding: 0 36px;
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 100%;
      @media (max-width: 767px) {
        padding: 0 24px;
      }
    }
  }

  &-alert {
    background-color: $red-500;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 56px;
    font-size: 10px;
    color: $white;
    box-sizing: border-box;
    padding: 4px 20px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 4px;
      padding: 4px 12px;
      align-items: flex-start;
    }

    &:first-child {
      border-radius: 0 0 5px 5px;
    }

    &__data {
      display: flex;
      gap: 46px;
      min-width: 156px;
      align-items: center;
      @media (max-width: 767px) {
        min-width: 12px;
      }
    }

    &__type {
      display: inline-flex;
      align-items: center;
      gap: 18px;
      font-weight: 700;
      @media (max-width: 767px) {
        min-width: 80px;
      }
    }

    &-list {
      position: relative;
      z-index: 1;
      margin-top: -10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &-btn {
    min-height: 30px;
    min-width: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: $blue-dark;
    border-radius: 5px;
    color: $white;
    font-weight: 700;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 14px;
    box-sizing: border-box;
    padding: 4px;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    transition: opacity .2s linear;

    &:hover {
      opacity: .75;
    }

    &--disabled {
      pointer-events: none;
      background-color: $gray-300;
      color: $gray-text;

      &:after {
        content: "\e955";
        font-family: icomoon;
        display: inline-block;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        width: 10px;
        min-width: 10px;
        height: 10px;
        line-height: 1;
      }
    }

  }


  &-pagination {
    display: flex;
    align-items: center;
    gap: 36px;
    color: $gray-800;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    &__label {
      font-size: 14px;
    }

    &-container {
      display: flex;
      gap: 24px;
      @media (max-width: 767px) {
        gap: 12px;
      }
    }

    &-nav {
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #0891B2;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        color: $gray-text;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &-icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
      font-size: 14px;
      box-sizing: border-box;
      padding: 4px 6px;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $gray-800;
      cursor: pointer;
      line-height: 1;

      &.active {
        pointer-events: none;
        color: $white;
        background-color: #0891B2;
      }
    }
  }
}

.drdm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &.close {
    display: none;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 26, 29, .7);
  }

  &-scroll-wrapper {
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    max-height: 74vh;
    gap: 11px;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 8px;
      background-color: #E8E8E8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #A4A4A4;
    }

    @media (max-width: 767px) {
      max-height: calc(100vh - 100px);
    }

    .drdm-form {
      max-height: 100%;
    }
  }

  &-modal {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 584px;
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 11px;
    max-height: 100vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
      background-color: #E8E8E8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #A4A4A4;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      height: 100vh;
      border-radius: 0;
    }
    @media (max-width: 520px) {
      padding: 30px 15px;
    }

    &--sm {
      max-width: 294px;
      height: auto;
      padding: 20px 40px;
      text-align: center;
      gap: 0;

      &-2 {
        text-align: center;
        max-width: 386px;
        gap: 10px;
      }
    }

    &-alert {
      font-size: 16px;
      font-weight: 700;
      color: $blue-dark;

      &.txt-red {
        color: $red-500;
      }

      &-btn {
        &-wrapper {
          display: flex;
          justify-content: center;
          gap: 10px;
          padding-top: 20px;
        }
      }
    }
  }

  &-title {
    margin: 0;
    font-size: 22px;
    color: $black-2;
    font-weight: 700;

    &-2 {
      color: $blue-dark;
      font-weight: 700;
      font-size: 20px;
      margin: 0 0 6px;
    }
  }

  &-about {
    color: $gray-700;
    font-size: 14px;
    max-width: 412px;
  }

  &-headline {
    display: flex;
    gap: 4px;
  }

  &-client {
    color: $blue-dark;
    white-space: nowrap;
    font-size: 20px;
    @media (max-width: 520px) {
      font-size: 16px;
    }
  }

  &-tabs {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__head {
      width: 100%;
      display: flex;
      gap: 15px;
      border-bottom: 1px solid $blue-dark;
      @media (max-width: 520px) {
        gap: 8px;
      }
    }
  }

  &-tab {
    display: none;
    @media (max-width: 767px) {
      &[data-tab="survey"] .drdm-form {
        max-height: calc(100vh - 240px);
      }
    }
    @media (max-width: 374px) {
      &[data-tab="survey"] .drdm-form {
        max-height: calc(100vh - 272px);
      }
    }

    &-btn {
      position: relative;
      box-sizing: border-box;
      padding: 0 32px;
      height: 40px;
      flex-grow: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;
      color: $blue-dark;
      background-color: $gray-200;
      border-radius: 6px 6px 0 0;
      font-weight: 700;
      font-size: 14px;
      transition: color .2s linear, background-color .2s linear;
      @media (max-width: 767px) {
        padding: 0 4px;
      }

      &:hover {
        background-color: $blue-light;
      }

      &.active {
        pointer-events: none;
        color: $white;
        background-color: $blue-dark;
      }

      &-error {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 5px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2px;
        color: $red-500;
        @media (max-width: 767px) {
          position: static;
          transform: translateY(0);
        }
        @media (max-width: 339px) {
          display: none;
        }

        &:before {
          content: "\e958";
          font-family: icomoon;
          width: 14px;
          min-width: 14px;
          height: 14px;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &--alert {
          position: static;
          transform: translateX(0);
          color: $white;
        }
      }
    }

    &.active {
      display: block;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    max-height: 66vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
      background-color: #E8E8E8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #A4A4A4;
    }

    @media (max-width: 767px) {
      max-height: calc(100vh - 170px);
    }
    @media (max-width: 374px) {
      max-height: calc(100vh - 186px);
    }

    &-btn {
      width: 100%;
      font-size: 14px;
      background-color: $blue-1;
      border-radius: 6px;
      min-height: 35px;

      &--disabled {
        pointer-events: none;
        background-color: #d1d5db;
        color: #6b7280;
      }

      &-wrapper {
        padding-top: 20px;
      }
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-size: 16px;
      text-transform: uppercase;
      color: $gray-700;
    }

    &-sub {
      font-size: 10px;
      font-style: italic;
      color: $gray-700;
    }

    &-radio {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &-item {
      width: calc(50% - 4px);
      display: flex;
      flex-direction: column;
      gap: 8px;
      @media (max-width: 520px) {
        width: 100%;
      }
    }

    .drdm-block__textarea {
      resize: none;
      min-height: 60px;
      background-color: transparent;

      &::-webkit-scrollbar {
        width: 3px;
        border-radius: 5px;
        background-color: #E8E8E8;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #A4A4A4;
      }
    }
  }

  &-variants {
    display: flex;
    gap: 38px;
    align-items: center;
    @media (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      margin-bottom: 12px;
    }

    &__label {
      width: calc(62% - 20px);
      max-width: 272px;
      font-size: 14px;
      color: $gray-700;
      align-self: flex-end;
      @media (max-width: 520px) {
        width: 100%;
        align-self: flex-start;
      }
    }

    &__container {
      width: calc(38% - 20px);
      display: flex;
      justify-content: space-between;
      @media (max-width: 520px) {
        width: 100%;
        .drdi-radio {
          width: auto;
        }
      }
    }

    &-variant {
      display: flex;
      flex-direction: column;
    }
  }

  &-remove {
    width: 20px;
    min-width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    color: $blue-1;
    font-style: normal;
    cursor: pointer;
    transition: color .2s linear;

    &:hover {
      color: $blue-dark;
    }

    &:after {
      content: "\e95b";
      font-family: icomoon;
    }
  }

  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: $gray-text;
    border-radius: 50%;
    cursor: pointer;
    font-style: normal;
    line-height: 1;
    transition: background-color 0.2s linear, color 0.2s linear;

    &:after {
      content: "\e944";
      font-family: icomoon;
    }

    &:hover {
      color: $gray-400;
      background-color: $gray-100;
    }
  }

  &-alert {
    &-block {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .drdt-alert {
        padding-left: 12px;

        &:first-child {
          border-radius: 5px;
        }
      }
    }
  }

  &-download {
    width: auto;
    font-size: 14px;
    color: #0891B2;
    text-decoration: underline !important;
    transition: color .2s linear;

    &:hover {
      color: $blue-dark;
    }

    &-wrapper {
      display: flex;
      gap: 12px;
      justify-content: center;
      margin-bottom: 12px;
    }
  }

  &-upload {
    &-btn {
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  &[data-modal="add-participant"] {
    .drdm-modal {
      max-height: 768px;
    }
  }
}

.drdi {
  &-radio {
    @media (max-width: 520px) {
      width: 100%;
      br {
        display: none;
      }
    }

    &__field {
      display: none;
    }

    &__field:checked ~ .drdi-radio__label {
      &:before {
        border-color: $blue-1;
      }

      &:after {
        opacity: 1;
      }
    }

    &__label {
      display: inline-flex;
      cursor: pointer;
      align-items: flex-start;
      position: relative;
      gap: 5px;
      font-size: 14px;
      line-height: 20px;
      color: $gray-700;

      &:before {
        content: '';
        width: 15px;
        min-width: 15px;
        height: 15px;
        display: inline-block;
        border: 1px solid $gray-700;
        border-radius: 50%;
        transform: translateY(2px);
        transition: border-color .2s linear;
      }

      &:after {
        content: '';
        width: 9px;
        height: 9px;
        display: inline-block;
        border-radius: 50%;
        background-color: $blue-1;
        position: absolute;
        left: 3px;
        top: 5px;
        opacity: 0;
        transition: opacity .2s linear;
      }

      &--variant {
        flex-direction: column-reverse;
        align-items: center;
        font-size: 10px;
        line-height: 1;

        &:after {
          top: 20px;
        }
      }
    }
  }
  &-check {
    &__field {
      display: none;
    }

    &__field:checked ~ .drdi-check__label {
      &:after {
        border-color: $blue-1;
        background-size: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 6'%3E%3Cpath d='M8.00168 0.667509C8.00168 0.844543 7.93136 1.01433 7.80618 1.13951L3.13951 5.80618C3.07758 5.86826 3.00401 5.91752 2.92302 5.95113C2.84203 5.98473 2.7552 6.00203 2.66751 6.00203C2.57982 6.00203 2.49299 5.98473 2.412 5.95113C2.331 5.91752 2.25744 5.86826 2.19551 5.80618L0.195509 3.80618C0.0703268 3.68099 0 3.51121 0 3.33418C0 3.15714 0.0703268 2.98736 0.195509 2.86218C0.320691 2.73699 0.490475 2.66667 0.667509 2.66667C0.844543 2.66667 1.01433 2.73699 1.13951 2.86218L2.66751 4.39151L6.86218 0.195509C6.98736 0.0703268 7.15714 0 7.33418 0C7.51121 0 7.68099 0.0703268 7.80618 0.195509C7.93136 0.320691 8.00168 0.490475 8.00168 0.667509Z' fill='%2302577E'/%3E%3C/svg%3E");
      }

    }

    &__label {
      display: inline-flex;
      cursor: pointer;
      align-items: flex-start;
      position: relative;
      gap: 5px;
      font-size: 14px;
      line-height: 20px;
      color: $gray-700;

      &:after{
        content: '';
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-left: 4px;
        display: inline-block;
        border: 1px solid $gray-700;
        border-radius: 4px;
        transform: translateY(2px);
        transition: border-color .2s linear;
      }

    }
  }
  &-input {
    position: relative;
    border: 1px solid $gray-300;
    border-radius: 6px;
    display: flex;

    &--date {
      &:after {
        content: "\e95a";
        font-family: icomoon;
        width: 18px;
        min-width: 18px;
        height: 18px;
        font-size: 18px;
        color: $gray-600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }

    &__date {
      height: 40px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
      }

      &::-webkit-inner-spin-button, &::-webkit-clear-button {
        z-index: 1;
      }

      &-field {
        height: 40px !important;
        width: 100% !important;
        position: relative;
        z-index: 1;
        box-shadow: none !important;
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 16px;
        text-transform: uppercase;

        &::placeholder {
          color: $gray-400 !important;
        }

        &::-webkit-calendar-picker-indicator {
          background: transparent;
        }

        &::-webkit-inner-spin-button, &::-webkit-clear-button {
          z-index: 1;
        }
      }

      &-value {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding: 4px 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-size: 16px;
        color: $gray-700;
        line-height: 1;
        gap: 12px;
      }
    }

    &__field {
      width: 100%;
      height: 40px !important;
      font-size: 16px;
      box-sizing: border-box;
      padding: 4px 13px;
      color: $gray-700;
      line-height: 1;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

      &::placeholder {
        opacity: 1 !important;
        color: $gray-400-2 !important;
      }
    }

    &__icon {
      width: 40px;
      min-width: 40px;
      background-color: $green-600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-style: normal;
      font-size: 16px;
      border-radius: 6px 0 0 6px;
    }


    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &--error {
        .drdi-label {
          color: $red-500;
        }

        .drdi-select__head, .drdi-input {
          border-color: $red-500;
        }

        .epp-input__alert {
          display: inline-block;
        }

        .drdi-input__date-value {
          color: $red-500;
        }
      }
    }


  }

  &-label {
    display: block;
    font-size: 14px;
    color: $gray-700;

    &-wrapper {
      width: 100%;
      display: flex;
      gap: 6px;
    }

    &__info {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &-select, &-multiselect {
    position: relative;

    &__head {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 32px 0 12px;
      min-height: 2.5rem;
      border-radius: 5px;
      background-color: #fff;
      font-size: 16px;
      color: #374151;
      border: 1px solid $gray-300;
      box-shadow: none;
      outline: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 8px;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9L12 16L5 9' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        transition: transform .2s linear;
        pointer-events: none;
      }

      &--text {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        padding-right: 32px !important;
      }
    }

    &__menu {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      background-color: $white;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 15px;
      border-radius: 5px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
      min-width: 150px;
      z-index: 3;
      border: 1px solid rgba(0, 0, 0, .06);

      &--top {
        top: auto;
        bottom: 100%;
        box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0px -2px 4px -1px rgba(0, 0, 0, 0.06);

      }
    }

    &__item {
      cursor: pointer;

      &--hover {
        color: $gray-text;
        margin: 0 -15px;
        box-sizing: border-box;
        padding: 3px 15px;
        font-size: 16px;
        transition: background-color .2s linear, color .2s linear;

        &:hover {
          background-color: $gray-200;
        }
      }
    }

    &__input {
      display: none !important;
    }
  }

  &-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 30px;
    background-color: $gray-300;
    border-radius: 2px;
    transition: background-color .2s linear;

    &:not(.drdi-upload--file):hover {
      background-color: $blue-light-2;
    }

    &--file {
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-right: 8px;

      .icon-fss-remove {
        color: $blue-dark;
        cursor: pointer;
        opacity: .6;
        transition: opacity .15s linear;

        &:hover {
          opacity: 1;
        }
      }

      .drdi-upload__label {
        max-width: calc(100% - 24px);
      }
    }

    &__field {
      width: 100%;
      height: calc(100% + 35px) !important;
      position: absolute;
      left: 0;
      top: -35px;
      opacity: 0;
      cursor: pointer;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 8px;
      width: 100%;
      gap: 5px;
      color: $blue-dark;

      &:before {
        content: "\e95c";
        font-family: icomoon;
        font-size: 15px;
        line-height: 1;
        min-width: 15px;
        width: 15px;
        height: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &-value {
        display: inline-block;
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
      }
    }

    &-wrapper {
      width: 100%;
      max-width: 220px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__file {
      &-list {
        width: 100%;
        max-width: 220px;
        display: flex;
        flex-direction: column;
        gap: 10px
      }

      &-input {
        display: none;
      }

      &-types {
        color: #1f2937;
        font-size: 11px;
        margin-top: -5px;
      }
    }

  }

  &-multiselect {
    &__head {
      display: block;
      line-height: 2.3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__item {
      position: relative;
      padding-right: 32px;

      &:after {
        content: '\e92e';
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: $blue-dark;
        pointer-events: none;
        opacity: 0;
        transition: opacity .2s linear;
      }

      &.check:after {
        opacity: 1;
      }
    }
  }

  &-textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__field {
      width: 100%;
      resize: none;
      border-radius: 6px;
      border: 1px solid $gray-300 !important;
      height: 205px !important;
      font-size: 16px;
      box-sizing: border-box;
      padding: 9px 13px;
      color: $gray-700;
      line-height: 1;
      box-shadow: none !important;
      outline: none !important;

      &::placeholder {
        opacity: 1 !important;
        color: $gray-400-2 !important;
      }

      @media (min-width: 768px) {

        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: $gray-text;

        }
      }
    }

  }
}

//Hide Calendar icon for Mozilla Firefox
@-moz-document url-prefix() {
  .drdi-input--date::after {
    display: none !important;
  }
}

.c-error {
  color: $red-500;
}
