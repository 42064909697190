.options-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.empty-cell-text {
  color: #9ca3af;
}

.matching-container {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.matching-left-items {
  margin-bottom: 2rem;
}

.matching-row {
  display: flex;
  gap: 3rem;
  margin-bottom: 1rem;
}

.matching-question,
.matching-answer-cell {
  border: 1px solid #9ca3af;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  width: 100%;
}

.matching-answer-cell {
  border-style: dashed;

  .matching-option {
    padding: 0;
    border: none;
  }
}

.matching-divider {
  border-bottom: 1px solid #9ca3af;
  margin: 2rem 0;
  width: 100%;
}

.matching-options {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 20px;
}

.matching-option {
  border: 1px solid #9ca3af;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  cursor: move;
}

@media (max-width: 991px) {
  .matching-row {
    gap: 1rem;
  }

  .matching-option {
    width: 100%;
  }

  .matching-question,
  .matching-answer-cell,
  .matching-option {
    font-size: 14px;
    padding: 1rem;
  }
}

.answer-incorrect {
  background-color: #ef5753;
}

.answer-correct {
  background-color: #51d88a;
}

.matching-error {
  color: #ef5753;
}

.hidden-error {
  display: none !important;
}
