@import "./base/variables";

.benefits-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin: 50px auto;
  p{
    font-size: 16px;
  }
  @media (max-width: 940px) {
    margin: -36px auto 0;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    .table{
      margin-right: -4px;
      margin-left: -4px;
      width: calc(100% + 8px);
    }
    .btn-primary{
      font-weight: 400;
    }
  }
}

.benefits-wrapper .benefits-block--top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.benefits-wrapper .benefits-block--top .logo img {
  width: 100%;
  height: 65px;
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
}

.benefits-wrapper .benefits-block--top .heading {
  font-family: "League Spartan", sans-serif;
  color: #ef6f21;
  font-size: 38px;
  line-height: 1;
  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 26px;

  }
}

.benefits-wrapper .benefits-block--table .ms-table {
  width: 100%;
  table-layout: fixed;
}

.benefits-wrapper .benefits-block--table .table,
.benefits-wrapper .benefits-block--table .th,
.benefits-wrapper .benefits-block--table .td {
  border: 4px solid #fbfbfb;
}

.benefits-wrapper .benefits-block--table .table .benefit__description,
.benefits-wrapper .benefits-block--table .th .benefit__description,
.benefits-wrapper .benefits-block--table .td .benefit__description {
  /*display: block !important;*/
}

.benefits-wrapper .benefits-block--table .table .tr .td {
  padding: 16px 12px;
}

.benefits-wrapper .benefits-block--table .table .tr .td:nth-child(1) {
  max-width: 230px;
  min-width: 230px;
  width: 230px;
  padding: 16px;
  display: flex !important;
  @media (max-width: 940px) {
    max-width: 142px;
    min-width: 142px;
    width: 142px;
    .text-lg{
      font-size: 13px;
    }
    .text-sm{
      font-size: 11px;
    }
  }
}

.benefits-wrapper .benefits-block--table .thead .tr .td {
  background: #02577e;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 1.4;
  min-height: 139px;
}

.benefits-wrapper .benefits-block--table .thead .tr .td p {
  font-size: 18px;
  color: #fff;
}

.benefits-wrapper .benefits-block--table .thead .tr .td p span {
  display: block;
  text-align: center;
}

.benefits-wrapper .benefits-block--table .thead .tr .td p span.font-weight-bold {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 1;
  @media (max-width: 940px) {
    font-weight: 14;
    margin-bottom: 10px;
  }
}

.benefits-wrapper .benefits-block--table .thead .tr .td .no-underline {
  max-height: 40px;
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.benefits-wrapper .benefits-block--table .thead .tr .td p span.font-size-small {
  font-size: 13px;
  margin-top: 0;
}

.benefits-wrapper .benefits-block--table .tbody .tr:nth-child(1) {
  border-top: none;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td {
  background: #e6eef2 !important;
  color: #02577e !important;
  font-size: 18px;
  line-height: 1.3;
  vertical-align: middle;
  text-align: center;
  font-weight: 600;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td p {
  color: #02577e !important;
  margin-bottom: 0;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td.recommend-blue {
  background: #9abccb !important;
  color: #fff;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td:nth-child(1) {
  text-align: left;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td .font-size-small {
  font-size: 13px;
  line-height: 1.1;
  display: block;
  font-weight: 500;
  margin-top: 8px;
}

.benefits-wrapper .benefits-block--table .tbody .tr .td .i-checked {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.benefit__title .item-tooltip {
  min-width: 15px;
  min-height: 15px;
  background: #02577e;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  margin: 0 0 auto auto;
  font-family: emoji;
}

.benefit {
  &-section {
    width: 100%;
    background-color: $blue-1;
    box-sizing: border-box;
    padding: 80px 20px;
    margin: 48px 0;
    @media (max-width: 767px) {
      padding: 40px 15px;
      margin: 8px 0 24px;
    }
  }
  &-form{
    max-width: 720px;
    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      .text-4xl{
        font-size: 32px;
        max-width: 260px;
      }
      &__title{
        text-align: center;
        max-width: 340px;
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;
      }
      &__field{
        width: 100%;
        padding: 0;
      }
      .btn-primary{
        width: 100%;
        max-width: 450px;
      }
    }
  }

  &-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__title {
      color: $white;
      font-family: $font-secondary;
      line-height: 1.5;
      margin: 0;
      font-weight: bold;
      text-align: center;
      font-size: 32px;
    }
  }

  &-btn {
    width: auto;
    text-align: center;
    outline: 0;
    text-decoration: none;
    cursor: pointer;
    min-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px 21px;
    border-radius: 4px;
    background-color: #ef6f21;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    transition: background-color .25s linear;

    &:hover {
      background-color: #f97316;
    }
  }

}

.flex-column {
  flex-direction: column;
}

.m-none {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.benefits-control{
  line-height: 1.15;
  font-size: 20px;
  font-family: $font-secondary;
  color: $gray-500;
  transition: color .2s linear;
  cursor: pointer;
  box-sizing: border-box;
   &:hover{
    color: $gray-400;
  }
  &.active{
    padding: 12px 14px 8px;
    color: $white;
    pointer-events: none;
    border-radius: 6px;
    background-color: $blue-1;
  }

  &-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 24px;
    @media (min-width: 768px) {
      display: none;
    }
  }
}

#benefits{
  @media (max-width: 767px) {
    .text-5xl, .text-4xl{
      font-size: 32px;
    }
    .-mx-4{
      margin-right: 0;
      margin-left: 0;
    }
    section.flex-wrap{
      width: 100%;
    }
  }

}
