.enroll {
  overflow: hidden;
  background-color: $white;

  &-container {
    position: relative;
    z-index: 1;
    max-width: 1358px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1139px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &--sm {
      max-width: 964px;
    }
  }

  &-hero {
    height: 39.4vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;

    @media (max-width: 600px) {
      height: auto;
      line-height: 0;
    }

    &__about {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      box-sizing: border-box;
      padding: 40px 0;
      max-width: 491px;
      width: 100%;
      @media (max-width: 600px) {
        padding: 30px 0 0;
      }
    }

    &__image {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      min-width: 300px;

      .label-enroll {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        position: relative;
        left: 54%;
        bottom: 5.6vw;
        padding: 14px;

        z-index: 5;
        transform: translateX(-50%);
        background: #02577E;
        font-weight: 400;
        font-size: 26px;
        border-radius: 10px;
        width: 417px;


        @media (max-width: 900px) {
          font-size: 20px;
          width: fit-content;
          bottom: 4.5vw;
          left: 50%;
        }
        @media (max-width: 800px) {
          font-size: 18px;
        }


        @media (max-width: 600px) {
          position: static;
          transform: translate(0);
          font-size: 16px;
          margin-top: -20px;
          margin-bottom: 30px;
          width: 276px;
        }
      }

      &.contact_landing {
        @media (max-width: 1100px) {
          width: 60%;
          height: auto;
          top: 50%;
          left: 70%;
          transform: translate(-50%, -50%);

          &:before {
            width: 20%;
          }
        }

        @media (max-width: 800px) {
          width: 50%;
          left: 75%;
        }
        @media (max-width: 600px) {
          position: static;
          transform: translate(0);
          margin: 0 auto;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 100%;
        background-image: linear-gradient(90deg, $white 10%, rgba($white, 0) 100%);
        @media (max-width: 600px) {
          width: 100%;
          height: 40%;
          background-image: linear-gradient($white 10%, rgba($white, 0) 100%);
        }
      }

  /*    @media (max-width: 1100px) {
        width: 60%;
        height: auto;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);

        &:before {
          width: 20%;
        }
      }

      @media (max-width: 800px) {
        width: 50%;
        left: 75%;
      }*/

      @media (max-width: 600px) {
        position: static;
        transform: translate(0);
        margin: 0 auto;
      }
    }

    &__title {
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 48px;
      margin: 0 0 20px;
      color: $black;
      line-height: 1.2;
      @media (max-width: 990px) {
        font-size: 32px;
        max-width: 330px;
      }
      @media (max-width: 600px) {
        margin: 0;
        line-height: normal;
      }
    }

    &__subtitle {
      font-size: 26px;
      margin: 0 0 26px;
      color: #4B5563;
      @media (max-width: 990px) {
        font-size: 22px;
      }
      @media (max-width: 600px) {
        margin-bottom: 15px;
        line-height: normal;
      }
    }

    &__form {
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__input {
      width: 300px !important;
      border: 1px solid #0E7490 !important;
      padding: 14px !important;
      box-shadow: none !important;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      color: $black !important;
      min-height: 49px;
      margin-right: 25px;

      &::placeholder {
        color: #9CA3AF !important;
      }

      @media (max-width: 990px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        width: 250px !important;
        min-height: 40px;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &__btn {
      min-height: 49px;
      box-sizing: border-box;
      color: $white;
      font-weight: 400;
      background-color: $blue-1;
      border-radius: 4px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      @media (max-width: 990px) {
        font-size: 16px;
        line-height: normal;
      }
      @media (max-width: 600px) {
        align-self: flex-start;
        min-height: 45px;
        margin: 0 auto;
      }

      &.enroll-waitlist {
        @media (max-width: 600px) {
          margin-left: 0;
        }
      }

      &.schedule-btn {
        width: 216px;
        text-decoration: none;

        @media (max-width: 600px) {
          width: 187px;
        }
      }
    }

    &__arrow {
      padding-top: 45px;
      position: relative;
      font-size: 18px;
      color: #4B5563;
      margin: 0;
      @media (max-width: 990px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        padding-top: 15px;
        line-height: normal;
      }



      &:before {
        content: '';
        position: absolute;
        top: 17px;
        right: 108px;
        width: 67px;
        height: 38px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 67 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.1747 5.42118C48.5522 6.09158 45.9297 6.76198 43.3143 7.44544C41.9955 7.75098 41.509 5.72055 42.7876 5.40294C45.976 4.56268 49.1121 3.75058 52.2885 2.95053L60.5509 0.82778C61.7893 0.498109 63.2126 0.136274 64.3545 1.00476C65.2792 1.72045 65.5285 2.93468 65.629 4.06047C65.7828 6.0317 65.8235 8.01303 65.8642 9.99158C65.881 10.8057 65.8977 11.6193 65.9223 12.4315C66.0509 15.3625 66.1393 18.2815 66.2277 21.2005C66.3081 22.5394 64.1812 22.471 64.141 21.1442C64.0948 19.6184 64.0385 18.0895 63.9823 16.5606C63.926 15.0318 63.8698 13.5029 63.8235 11.9771C63.7994 11.1786 63.7825 10.3823 63.7656 9.58838C63.7344 8.1197 63.7034 6.65925 63.6269 5.2084C60.9261 9.32367 57.7705 13.1455 54.3129 16.6177C47.6104 23.3281 39.6213 28.7759 30.9165 32.519C26.4977 34.3925 21.882 35.9002 17.1577 36.8932C11.7459 37.9867 5.85965 38.3243 0.849966 35.5942C0.363472 35.3168 0.222758 34.6172 0.500193 34.1307C0.817834 33.6563 1.46516 33.5437 1.95166 33.8211C7.15031 36.6518 13.4949 35.6628 18.9791 34.3281C27.8648 32.1732 36.2841 28.213 43.7064 22.9018C51.1808 17.5625 57.6422 10.7798 62.5596 3.00798C62.675 2.81709 62.8213 2.68858 62.9816 2.61221C62.8804 2.56507 62.7614 2.5404 62.6215 2.54464C62.1882 2.53893 61.7589 2.66664 61.3437 2.79016C61.1733 2.84084 61.0053 2.89081 60.8404 2.93058C60.5308 3.013 60.2343 3.08838 59.9377 3.16376C59.6412 3.23914 59.3447 3.31452 59.0351 3.39694C56.4197 4.0804 53.7972 4.75079 51.1747 5.42118Z' fill='%23EF6F21'/%3E%3C/svg%3E");
        @media (max-width: 600px) {
          top: -22px;
          width: 39px;
          height: 23px;
          left: 157px;
          transform: rotate(250deg);
        }
      }
    }
  }

  &-scn {
    &-1 {
      position: relative;
      padding: 80px 0;
      @media (max-width: 800px) {
        padding: 50px 0;
      }
      @media (max-width: 600px) {
        padding: 30px 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: calc(100% - 316px);
        transform: translateY(-50%);
        background-color: $blue-1;

        @media (max-width: 600px) {
          top: 0;
          transform: translateY(0);
          height: calc(100% - 200px);
        }
      }

      &__image {
        width: 55.54%;
        @media (max-width: 600px) {
          width: 100%;
          order: 1;
        }
      }

      &__data {
        flex-grow: 1;
        box-sizing: border-box;
        padding: 122px 0;
        margin: auto 0 auto 42px;
        @media (max-width: 600px) {
          padding: 0;
          margin: 0 0 30px;
        }
      }

      &__row {
        display: flex;
        flex-direction: row;
        @media (max-width: 600px) {
          flex-direction: column;
        }
      }

      &__about {
        max-width: 480px;
        margin: 0;
        color: $white;
        line-height: 1.1;
        font-size: 48px;
        @media (max-width: 990px) {
          font-size: 20px;
        }
      }
    }

    &-2 {
      background: #F9FAFB;
      @media (max-width: 1139px) {
        background: #F9FAFB;
      }
      @media (max-width: 600px) {
        padding: 30px 0;
      }

      &__row {
        display: flex;
        flex-direction: row;
        padding-right: 0;
        @media (max-width: 600px) {
          flex-direction: column;
          padding-right: 15px;
        }
      }

      &__data {
        flex-grow: 1;
        box-sizing: border-box;
        margin: auto 45px auto 0;
        @media (max-width: 600px) {
          margin: 0 0 30px;
        }
      }

      &__image {
        width: 51%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }

      &__about {
        max-width: 532px;
        margin: 0;
      }

      &__title {
        font-family: $font-secondary;
        color: #1F2937;
        font-weight: 700;
        font-size: 40px;
        margin: 0 0 15px;
        @media (max-width: 990px) {
          font-size: 26px;
        }
      }

      &__text {
        max-width: 500px;
        font-size: 26px;
        color: #1F2937;
        margin: 0;
        @media (max-width: 990px) {
          font-size: 16px;
        }
      }
    }

    &-3 {
      &__row {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        @media (max-width: 600px) {
          flex-direction: column;
        }
      }

      &__image {
        width: 51.7%;
        @media (max-width: 600px) {
          width: 100%;
          order: 1;
        }
      }

      &__data {
        flex-grow: 1;
        box-sizing: border-box;
        padding: 40px 0;
        margin: auto 0;
        @media (max-width: 600px) {
          padding: 30px 0 0;
        }
      }

      &__about {
        max-width: 500px;
        margin: 0;
        color: #1F2937;
        font-size: 48px;
        @media (max-width: 990px) {
          font-size: 26px;
        }
      }
    }

    &-4{
      background: #F9FAFB;
    }
  }

  &-line {
    box-sizing: border-box;
    padding: 80px 0 82px;
    background-color: #02577E;
    @media (max-width: 600px) {
      padding: 30px 0;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 1139px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__data {
      flex-grow: 1;
      margin-right: 70px;
      @media (max-width: 1139px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    &__about {
      font-size: 32px;
      color: $white;
      @media (max-width: 990px) {
        font-size: 20px;
        //max-width: 460px;
        br {
          display: none;
        }
      ;
      }

      &--mw-570 {
        //max-width: 570px;
        @media (max-width: 990px) {
          max-width: 267px;
        }
      }
    }

    &__form {
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__input {
      width: 300px !important;
      border: none !important;
      padding: 14px !important;
      box-shadow: none !important;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      color: $black !important;
      min-height: 50px;
      background-color: $white;
      margin-right: 25px;
      @media (max-width: 990px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        width: 250px !important;
        min-height: 40px;
        margin-right: 0;
        margin-bottom: 15px;
      }

      &::placeholder {
        color: #9CA3AF !important;
      }
    }

    &__btn {
      min-height: 50px;
      box-sizing: border-box;
      white-space: nowrap;
      color: $white;
      font-weight: 400;
      background-color: $orange;
      border-radius: 4px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      @media (max-width: 990px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        min-height: 40px;
        align-self: flex-start;
      }

      &.schedule-btn {
        width: 216px;
        text-decoration: none;

        @media (max-width: 600px) {
          width: 187px;
        }
      }
    }
  }

  &-faq {
    padding: 80px 0;
    @media (max-width: 600px) {
      padding: 30px 0;
    }

    &__card {
      box-sizing: border-box;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 2px solid #D1D5DB;

      &-head {
        box-sizing: border-box;
        padding-right: 32px;
        font-size: 26px;
        color: #000;
        cursor: pointer;
        position: relative;
        @media (max-width: 990px) {
          font-size: 20px;
        }

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 16px;
          right: 5px;
          width: 12px;
          height: 12px;
          border-left: 1px solid #1F2937;
          border-bottom: 1px solid #1F2937;
          transform: rotate(230deg);
          transition: transform .3s linear;
        }

        &.open:before {
          transform: rotate(315deg);
        }
      }

      &-body {
        display: none;
        padding-top: 15px;
        max-width: 725px;
        line-height: 1.15;
        font-size: 20px;
        color: #6B7280;
        @media (max-width: 990px) {
          font-size: 16px;
        }
        a {
          font-size: 20px;
          font-weight: 400;
          @media (max-width: 990px) {
            font-size: 16px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      display: inline-block;
      position: relative;
      padding-bottom: 4px;
      font-size: 40px;
      color: #1F2937;
      font-weight: 700;
      font-family: $font-secondary;
      margin: 0 auto;
      width: auto;
      @media (max-width: 990px) {
        font-size: 26px;
        padding-bottom: 0;
        line-height: 1;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $orange;
      }

      &-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  &-tms {
    padding: 80px 0;
    background-color: #F9FAFB;
    @media (max-width: 600px) {
      padding: 30px 0 40px;
    }

    &__title {
      display: inline-block;
      position: relative;
      line-height: 1.4;
      font-size: 40px;
      color: #1F2937;
      font-weight: 700;
      font-family: $font-secondary;
      margin: 0 auto;
      width: auto;
      @media (max-width: 990px) {
        font-size: 26px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        bottom: 0;
        width: calc(100% - 40px);
        height: 2px;
        background-color: $orange;
        @media (max-width: 990px) {
          left: 0;
          width: 100%
        }
      }

      &-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &__card {
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0 27px 22px -20px #E0E1E3;
      box-sizing: border-box;
      padding: 30px 40px;
      @media (max-width: 600px) {
        padding: 25px 23px;
      }

      &-list {
        display: flex;
        margin: 0 -12px;

        ul.slick-dots {
          position: absolute;
          bottom: -20px;
          background-color: transparent;
          width: auto;
          left: 50%;
          transform: translateX(-50%);
        }

        .slick-arrow {
          z-index: 3;
          top: 50%;
          width: 30px;
          height: auto;
          display: flex;
          align-items: center;
          background-color: transparent;

          &:before {
            line-height: 1;
            font-size: 70px;
            color: #BCBCBC;
          }
        }

        .slick-next {
          right: 0;
          @media (max-width: 600px) {
            right: 5px;
          }
        }

        .slick-prev {
          left: 0;
          @media (max-width: 600px) {
            left: 10px;
          }
        }

        @media (max-width: 900px) {
          padding-right: 50px;
          padding-left: 50px;
        }
        @media (max-width: 900px) {
          padding-right: 35px;
          padding-left: 35px;
        }
      }

      &-wrapper {
        box-sizing: border-box;
        padding: 24px 12px 50px;
        @media (max-width: 900px) {
          min-width: 100%;
          &.slick-slide {
            min-width: 0;
          }
        }
        @media (max-width: 600px) {
          padding: 24px 12px 40px;
        }
      }

      &-about {
        font-size: 26px;
        color: #1F2937;
        box-sizing: border-box;
        padding-top: 60px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        position: relative;
        @media (max-width: 600px) {
          font-size: 16px;
          padding-top: 45px;
          padding-bottom: 25px;
          margin-bottom: 25px;
        }

        &:before {
          content: '“';
          display: inline-block;
          font-family: $font-secondary;
          line-height: 1;
          font-size: 86px;
          color: $blue-1;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          @media (max-width: 600px) {
            font-size: 56px;
          }
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 2px;
          background-color: $blue-1;

        }
      }

      &-author {
        text-align: center;
        color: #1F2937;
        font-weight: 700;
        font-size: 18px;
        margin: 0 0 10px;
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }

      &-spec {
        margin: 0 auto;
        text-align: center;
        max-width: 200px;
        color: #6B7280;
        line-height: 1.15;
        font-size: 18px;
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
    }

    .slick-track {
      display: flex !important;
    }
  }
}

.testimonials-container {
  margin: 0 auto !important;
  max-width: 1358px;
}

.hover-blue:hover {
        background-color: #0374A8;
      }

.hover-orange:hover {
        background-color: #EA7935;
      }
